import { Link, Outlet } from "react-router-dom";
import Img from "./../Assets/images/auth.png"
import ScrollToTop from "../Components/LandingPage/NavBar/ScrollToTop";
import Toast from "../Components/Dashboard/Notification/Toast";

const AuthLayout = () => {
    return (
        <div className="font-montserrat grid grid-cols-1 lg:grid-cols-2 h-screen w-full overflow-hidden">
            <Toast />
            <div className="w-full h-full p-5 bg-[#f7f8f9] hidden lg:block lg:col-span-1 ">
                <ScrollToTop />
                <Link to="/"><img src={Img} alt="logo" className="object-fill" /></Link>
            </div>
            <div className="h-screen overflow-auto">
                <div className="w-full px-5">
                    <div className="max-w-md px-10 md:p-5 mx-auto">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout;