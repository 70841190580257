import HeroImage from "../../Assets/images/HeroImage.png";
import HeroBg from "../../Assets/images/HeroBg.png";
import useTitle from "../../Hooks/useTitle";

export default function ContactUs() {
    useTitle("Contact Us | FedhaGap SMEs");
    return (
        <div className="my-36 md:my-20">
            <div className="md:px-20">
                <header
                    style={{
                        backgroundImage: `url(${HeroBg})`,
                        backgroundPosition: "top",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                    }}
                    className="flex md:py-10 md:gap-10 md:px-10 flex-col-reverse md:flex-row gap-5"
                >
                    <div className="px-10 pt-5 pb-16 md:p-5 contact-us basis-1 md:basis-1/2 md:pt-28">
                        <h4 className="mb-6">Get In touch</h4>
                        <p>
                            Want to get in touch? We’d love to hear from you. Here’s how you
                            can reach us...
                        </p>

                        <div className="contact-info">
                            <div className="grid grid-cols-12 grid-col gap-5 md:gap-12 mt-8">
                                <div className="col-span-2 md:col-span-1">
                                    <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.9613 24.925C8.3337 24.925 0.499512 17.0909 0.499512 7.4633C0.499992 5.74145 1.12801 4.0788 2.266 2.78661C3.404 1.49443 4.97394 0.661302 6.68192 0.443201C7.18935 0.37974 7.70362 0.483684 8.14658 0.739235C8.58953 0.994785 8.93694 1.38797 9.136 1.85902L11.5075 7.38071C11.6641 7.73875 11.7288 8.13021 11.6959 8.5196C11.6629 8.90899 11.5333 9.284 11.3187 9.61062L9.50175 12.3715C10.3067 13.8926 11.5529 15.1347 13.0767 15.9346L15.8021 14.1176C16.13 13.9011 16.5064 13.7695 16.8977 13.7345C17.289 13.6995 17.6828 13.7622 18.0439 13.9171L23.5655 16.2886C24.0366 16.4876 24.4298 16.835 24.6853 17.278C24.9409 17.7209 25.0448 18.2352 24.9814 18.7426C24.7633 20.4506 23.9301 22.0206 22.6379 23.1586C21.3458 24.2965 19.6831 24.9246 17.9613 24.925ZM6.68192 3.31023C5.73283 3.51521 4.88262 4.03919 4.27292 4.79487C3.66322 5.55056 3.33084 6.49232 3.33115 7.4633C3.33427 11.3425 4.87666 15.0619 7.61966 17.8049C10.3627 20.5479 14.0821 22.0903 17.9613 22.0934C18.9322 22.0937 19.874 21.7613 20.6297 21.1516C21.3854 20.5419 21.9093 19.6917 22.1143 18.7426L17.159 16.6189L14.4217 18.4477C14.0813 18.6729 13.6884 18.8065 13.2813 18.8354C12.8741 18.8644 12.4663 18.7877 12.0974 18.6129C9.80728 17.5038 7.95467 15.6595 6.8353 13.3743C6.65528 13.0074 6.57471 12.5996 6.60163 12.1917C6.62854 11.7838 6.762 11.3902 6.98868 11.05L8.80564 8.26559L6.68192 3.31023Z"
                                            fill="#590DA4"
                                        />
                                    </svg>
                                </div>
                                <div className="col-span-9 md:col-span-10">
                                    <span>+234 802 433 2000</span>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 grid-col gap-5 md:gap-12 mt-8">
                                <div className="col-span-2 md:col-span-1">
                                    <svg
                                        width="25"
                                        height="20"
                                        viewBox="0 0 25 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.314941 2.57936C0.314941 1.95212 0.574541 1.35057 1.03663 0.907048C1.49872 0.463524 2.12545 0.214355 2.77894 0.214355H22.4909C23.1444 0.214355 23.7712 0.463524 24.2333 0.907048C24.6953 1.35057 24.9549 1.95212 24.9549 2.57936V16.7694C24.9549 17.3966 24.6953 17.9981 24.2333 18.4417C23.7712 18.8852 23.1444 19.1344 22.4909 19.1344H2.77894C2.12545 19.1344 1.49872 18.8852 1.03663 18.4417C0.574541 17.9981 0.314941 17.3966 0.314941 16.7694V2.57936ZM4.65035 2.57936L12.6349 9.28531L20.6195 2.57936H4.65158H4.65035ZM22.4909 4.1509L13.4468 11.7473C13.2222 11.9362 12.9336 12.0404 12.6349 12.0404C12.3362 12.0404 12.0477 11.9362 11.8231 11.7473L2.77894 4.1509V16.7694H22.4909V4.1509Z"
                                            fill="#590DA4"
                                        />
                                    </svg>
                                </div>
                                <div className="col-span-9 md:col-span-10">
                                    <span>contact@fedhagap.com</span>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 grid-col gap-5 md:gap-12 mt-8">
                                <div className="col-span-2 md:col-span-1">
                                    <svg
                                        width="21"
                                        height="27"
                                        viewBox="0 0 21 27"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.7496 12.9224C9.81571 12.9224 8.92002 12.5908 8.25962 12.0005C7.59923 11.4103 7.22822 10.6097 7.22822 9.77491C7.22822 8.94015 7.59923 8.13957 8.25962 7.5493C8.92002 6.95902 9.81571 6.62741 10.7496 6.62741C11.6836 6.62741 12.5793 6.95902 13.2397 7.5493C13.9001 8.13957 14.2711 8.94015 14.2711 9.77491C14.2711 10.1883 14.18 10.5975 14.003 10.9794C13.8261 11.3613 13.5667 11.7083 13.2397 12.0005C12.9127 12.2928 12.5245 12.5246 12.0972 12.6828C11.67 12.841 11.2121 12.9224 10.7496 12.9224ZM10.7496 0.961914C8.13461 0.961914 5.62668 1.89042 3.77758 3.54318C1.92847 5.19594 0.889648 7.43756 0.889648 9.77491C0.889648 16.3847 10.7496 26.1419 10.7496 26.1419C10.7496 26.1419 20.6096 16.3847 20.6096 9.77491C20.6096 7.43756 19.5708 5.19594 17.7217 3.54318C15.8726 1.89042 13.3647 0.961914 10.7496 0.961914Z"
                                            fill="#590DA4"
                                        />
                                    </svg>
                                </div>
                                <div className="col-span-9 md:col-span-10">
                <span>
                  Suite D-24, Dolphin Plaza, Corporation Drive, Dolphin Estate,
                  Ikoyi, Lagos
                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-10 hidden md:block pt-5 md:p-5 md:basis-1/2 basis-1 text-right">
                        <img src={HeroImage} alt="hero"/>
                    </div>
                </header>
            </div>
        </div>
    );
}
