import ResponseObject from "../../DataObjects/ResponseObject";
import UserObject from "../../DataObjects/UserObject";
import BaseController from "./BaseController";

class KYCController extends BaseController {
  async post({ request }) {
    try {
      let formData = Object.fromEntries(await request.formData());

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + UserObject.getUser().token);

      const transData = new FormData();
      Object.keys(formData).map((v) => transData.append(v, formData[v]));
      // transData.append('invoice', formData.invoice);
      // transData.append('invoice_number', formData.invoice_number);
      // transData.append('company', formData.company);
      // transData.append('due_date', formData.due_date);
      // transData.append('amount', formData.amount);
      // transData.append('service_category', formData.service_category);
      // transData.append('services_rendered', formData.services_rendered);
      // transData.append('mandate_letter', formData.mandate_letter);
      // transData.append('bank_statement', formData.bank_statement);
      // console.log(formData.recent_invoices);
      // transData.append('rencent_invoices', formData.recent_invoices);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: transData,
        redirect: "follow",
      };

      let response = await (
        await fetch("https://api.fedhagap.com/api/kyc", requestOptions)
      ).json();

      return ResponseObject.from({ data: response });
      // return response;
    } catch (e) {
      return e?.response;
    }
  }

  async put({ request }) {
    try {
      let formData = Object.fromEntries(await request.formData());

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + UserObject.getUser().token);

      const transData = new FormData();
      Object.keys(formData).map((v) => transData.append(v, formData[v]));
      // transData.append('invoice', formData.invoice);
      // transData.append('invoice_number', formData.invoice_number);
      // transData.append('company', formData.company);
      // transData.append('due_date', formData.due_date);
      // transData.append('amount', formData.amount);
      // transData.append('service_category', formData.service_category);
      // transData.append('services_rendered', formData.services_rendered);
      // transData.append('mandate_letter', formData.mandate_letter);
      // transData.append('bank_statement', formData.bank_statement);
      // console.log(formData.recent_invoices);
      // transData.append('rencent_invoices', formData.recent_invoices);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: transData,
        redirect: "follow",
      };

      let response = await (
        await fetch("https://api.fedhagap.com/api/kyc/resubmit", requestOptions)
      ).json();

      return ResponseObject.from({ data: response });
      // return response;
    } catch (e) {
      return e?.response;
    }
  }
}

export default KYCController;
