import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";
import BaseController from "./BaseController";

class MemberService extends BaseController {
    async index({ request, params }) {
        const url = new URL(request.url)
        try {
            let response = await http.get('/staff/list' + url.search + "&sort_type=DESC")
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    static async template({ request, params }) {
        try {
            let response = await http.get('/invoice/template')
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    static async email({ request, params }) {
        let formData = Object.fromEntries(await request.formData());
        try {
            let response = await http.put('/invoice/send-as-mail', formData)
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    async post({ request }) {
        let formData = Object.fromEntries(await request.formData());
        console.log(formData)
        try {
            let response = await http.post('/user/onboard', formData)
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    async put({ request }) {
        let formData = Object.fromEntries(await request.formData());
        try {
            let response = await http.put('/staff/update', formData)
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    async destroy({ request }) {
        let formData = Object.fromEntries(await request.formData());
        try {
            let response = await http.delete('/staff/delete/' + formData.profile_id)
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }
}

export default MemberService;