export const store = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const getFromStore = (key) => {
    return JSON.parse(localStorage.getItem(key))
}

export const removeFromStore = (key) => {
    localStorage.removeItem(key)
}