import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";
import BaseController from "./BaseController";

class OnboardService extends BaseController {

    async post({ request }) {
        let formData = Object.fromEntries(await request.formData());
        try {
            let response = await http.post(
                '/onboard/complete',
                formData,
            )
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }
}

export default OnboardService;