import Rectangle from "./../../Assets/images/Rectangle.png";
import Button from "./Button";

const JoinCommunity = () => {
  return (
    <section
      className="text-white border mb-20 w-full bg-left bg-cover md:pl-10 pl-2 rounded-3xl"
      style={{ backgroundImage: `url(${Rectangle})` }}
    >
      <div className="rounded-xl py-20 flex items-center justify-center md:justify-start w-full">
        <div className="inline-block md:w-[380px] p-6">
          <h4
            className="mb-1 font-bold"
            style={{ fontWeight: "900", fontSize: "28px", lineHeight: "32px" }}
          >
            Join Our Community
          </h4>
          <p className="mb-10 text-sm" style={{ fontSize: "16px" }}>
            Get the best info on time
          </p>
          {/* <form className="font-[700] text-[16px] leading-[24px] flex flex-col space-y-3">
            <input
              className="rounded-lg w-full focus:outline-none text-white placeholder:text-gray-50/70 bg-gray-500/70 px-[16px] py-[12px] text-sm"
              type="email"
              placeholder="Email Address"
            />
            <div className="items-center grid grid-cols-12 space-x-3">
              <select className="rounded-lg col-span-4 focus:outline-none text-white bg-gray-500/70 placeholder:text-gray-50/70 px-[16px] py-[12px] text-sm">
                <option>+234</option>
              </select>
              <input
                className="rounded-lg focus:outline-none  col-span-8 text-white placeholder:text-gray-50/70 bg-gray-500/70 px-[16px] py-[12px] text-sm"
                type="tel"
                placeholder="Phone Number"
              />
            </div> */}
            <Button to="/auth/register" className="w-full text-normal">Join Community</Button>
          {/* </form> */}
        </div>
      </div>
    </section>
  );
};

export default JoinCommunity;
