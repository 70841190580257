import Cleave from "cleave.js/dist/cleave-react"

const InvoiceInput = ({label, icon, error,normalize, ...props}) => {
    return (
        <div className="flex flex-col justify-start items-start relative mb-2 max-h-fit">
            <div className="flex items-center mt-1 justify-start w-full border-none">
                {
                    normalize
                        ?
                        <input
                            className="font-[700] w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                            {...props}
                        />
                        :
                        <Cleave
                        className="font-[700] w-full text-[12px] leading-[18px] py-[11px] px-[19px] placeholder:text-[#b8c0cc] border-[1.5px] rounded-[8px] border-[#b8c0cc] focus:outline-none"
                        {...props}
                    />

                }
            </div>
            <div
                className={`text-red-500 mx-2 text-sm font-semibold tracking-wide ${error ? '' : 'text-transparent'}`}
            >
                {Array.isArray(error) && error.length > 1 && (
                    <ul className="list-disc ml-5">
                        {error.map((e, k) => (
                            <li key={k}>{e}</li>
                        ))}
                    </ul>
                )}
                {Array.isArray(error) && error.length === 1 && error}
            </div>
        </div>
    )
};

export default InvoiceInput;