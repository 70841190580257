import Logo from "./../../../Assets/logo/Logo.png";
import { Navbar } from "flowbite-react";
import Button from "../Button";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Nav = () => {
  return (
    <Navbar
      fluid={false}
      rounded={false}
      className="navbar-container px-[10px] lg:px-[100px]"
    >
      <Link to="/">
        <img
          src={Logo}
          className="mr-3"
          alt="Fedhagap"
          style={{ height: "48px" }}
        />
      </Link>
      <div className="flex md:order-2">
        <Link
          className="border-[#590DA4] border-[1px] bg-white text-[#590DA4]  h-[50px] flex items-center justify-center px-[30px] rounded-[8px] hover:bg-[#590DA4] hover:text-white transition-all duration-300 py-[3px] mx-[10px]"
          to="/auth/login"
        >
          Login
        </Link>
        <span className="hidden md:inline-block">
          <Button
            style={{
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "3px 30px",
              marginRight: "10px",
            }}
            to="/auth/register"
          >
            Get started
          </Button>
        </span>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse className="navbar-text">
        <HashLink smooth to="/#products">
          <span>Products</span>
        </HashLink>
        <HashLink smooth to="/#how-it-works">
          <span>How it works</span>
        </HashLink>
        <HashLink smooth to="/#target-market">
          <span>Our Target Market</span>
        </HashLink>
        <Link to="/about-us">
          <span>About Us</span>
        </Link>
        <Link to="/contact-us">
          <span>Contact Us</span>
        </Link>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Nav;
