import { Suspense, useRef } from "react";
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { Await, useLoaderData } from "react-router-dom";
import useClickOutside from "../../../Hooks/useClickOutside";

const FAQ = () => {
    const items = useLoaderData()
    return (
        <div>
            <h2 className="font-[700] mt-[43px] mb-[33px] text-[30px] leading-[34.59px] tracking-[-2%]">Hi! How can we help you?</h2>
            <div className="flex flex-col w-full items-start justify-start space-y-3">
                <Suspense fallback={<p></p>}>
                    <Await
                        resolve={items.data}
                        errorElement={
                            <span>
                                Loading
                            </span>
                        }
                    >
                        {(data) => data?.data?.data?.map((v, k) => <Item key={k} data={v} />)

                        }
                    </Await>
                </Suspense>
            </div>
        </div>
    )
}


const Item = ({ data }) => {
    const detailRef = useRef();

    const handleOutsideClick = () => {
        detailRef.current.removeAttribute('open');
    }

    useClickOutside(detailRef, handleOutsideClick)

    return (
        <details ref={detailRef} className="py-[20px] px-[30px] bg-white w-full rounded-[10px] group border-b-[1px] border-[#590DA4]">
            <summary className="flex items-center font-[700] text-[20px] leading-3 justify-between w-full">
                <p className="w-full pr-4">{data?.question}</p>
                <button className="focus:outline-none border-none flex-1">
                    <BsPlus className="h-8 w-8 group-open:hidden" />
                    <BiMinus className="h-8 w-8 hidden group-open:inline-block" />
                </button>
            </summary>
            <p className="mt-5">
                {data?.answer}
            </p>
        </details>
    )
}

export default FAQ;