import { useEffect, useRef, useState } from "react";
import Input from "../../../Components/Forms/Input";
import { BsPlus } from "react-icons/bs";
import GeneralService from "../../../Services/Dashboard/GeneralService";

const TransactionCompany = ({ ...props }) => {
    const [addCompany, setAddCompany] = useState(false);

    return (
        <>
            {
                addCompany
                    ? (<Input
                        type="name"
                        {...props}
                        normalize={true}
                    />
                    )
                    : (
                        <div className="w-full mb-5 mt-3">
                            {/*<SearchSelect {...props}/>*/}
                            <CustomSelect {...props} />
                        </div>
                    )
            }
            <button type="button"
                className="underline text-purple-700 flex items-center font-semibold text-sm focus:outline-none"
                onClick={() => setAddCompany(v => v = !v)}>
                <BsPlus className="w-4 h-4" />
                <span>{!addCompany ? "Add Company" : "Select from Existing"}</span>
            </button>
        </>
    )
}

const CustomSelect = (props) => {

    const [search, setSearch] = useState('')
    const [field, setField] = useState(null);
    const [data, setData] = useState([]);
    const details = useRef();
    const check = useRef()
    const loadOptions = (inputValue) => {
        GeneralService.companies(inputValue).then(response => setData(response?.data?.data ?? []));
    };

    useEffect(() => {
        if (search !== '') {
            loadOptions(search);
        }
    }, [search])

    return (
        <details className="relative list-none" ref={details}>
            <summary className="list-none list-inside appearance-none px-4 form-input focus:outline-none text-gray-800 tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold placeholder:capitalize w-full">
                <span className="list-none">{field ?? 'Select Company'}</span>
            </summary>
            <ul className="absolute w-full mb-2 shadow-lg bg-white z-40">
                <li className="px-6"><Input normalize={true} placeholder="Search for company" type="text" onChange={e => {
                    setSearch(e.target.value)
                    setField(null)
                    check.current.checked = true
                }} value={search} />
                    <input type="radio" ref={check} {...props} className="hidden" value="" />
                </li>
                {data.map((v, i) =>
                    <li key={i} className="w-full">
                        <label onClick={() => {
                            setField(v.company_name)
                            details.current.removeAttribute('open')
                        }}>
                            <input type="radio" {...props} className="hidden peer" value={v.company_name} />
                            <span className="text-black w-full peer-checked:bg-blue-700 peer-checked:text-white hover:bg-blue-500 hover:text-white px-6 inline-block py-2 cursor-pointer">{v.company_name}</span>
                        </label>
                    </li>
                )}
                {data.length <= 0 && <li className="text-black px-6 inline-block py-2 cursor-pointer">No Data Available yet</li>}

            </ul>
        </details>
    )
}

export default TransactionCompany