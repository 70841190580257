import { BsChevronLeft } from "react-icons/bs";
import Panel from "../../../Components/Dashboard/Panel";
import { Await, Link, useLoaderData } from "react-router-dom";
import { Suspense } from "react";
import PaidStatus from "../../../Components/Dashboard/PaidStatus";
import MoneyFormat from "../../../Components/Utils/MoneyFormat";

const TransactionView = () => {
  const data = useLoaderData();
  return (
    <div>
      <button className="p-3 rounded-full bg-white">
        <Link to="/dashboard/transaction-history">
          <BsChevronLeft className="w-4 h-4" />
        </Link>
      </button>
      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={data}>
          {(data) => {
            let item = data?.data?.data;
            return (
              <div className="mt-5">
                <Panel>
                  <div className="grid md:grid-cols-5 grid-cols-1 py-5 px-2 gap-5">
                    <div className="col-span-1">
                      <h1 className="font-[500] text-[16px] leading-[24px]">
                        Invoice Number
                      </h1>
                      <p className="font-[700] text-[20px] leading-[30px]">
                        {item?.invoice_number}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <h1 className="font-[500] text-[16px] leading-[24px]">
                        Created Date
                      </h1>
                      <p className="font-[700] text-[18px] leading-[30px]">
                        {new Date(item?.created_at).toDateString()}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <h1 className="font-[500] text-[16px] leading-[24px]">
                        Due Date
                      </h1>
                      <p className="font-[700] text-[18px] leading-[30px]">
                        {new Date(item?.due_date).toDateString() ??
                          new Date().toString()}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <h1 className="font-[500] text-[16px] leading-[24px]">
                        Fund Status
                      </h1>
                      <p className="font-[700] text-[18px] leading-[30px]">
                        <PaidStatus value={item?.fund_status ?? 0} />
                      </p>
                    </div>
                    <div className="col-span-1">
                      <h1 className="font-[500] text-[16px] leading-[24px]">
                        Amount
                      </h1>
                      <p className="font-[700] text-[18px] leading-[30px]">
                        ₦{MoneyFormat(item?.amount)}
                      </p>
                    </div>
                  </div>
                </Panel>
                <div className="mt-10">
                  <Panel>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-10">
                      <div className="col-span-1">
                        <h1 className="font-[500] text-[16px] leading-[24px]">
                          Company
                        </h1>
                        <p className="font-[700] text-[18px] leading-[30px]">
                          {item?.company?.company_name}
                        </p>
                      </div>
                      <div className="col-span-1">
                        <h1 className="font-[500] text-[16px] leading-[24px]">
                          Service Category
                        </h1>
                        <p className="font-[700] text-[18px] leading-[30px]">
                          {item?.service_category}
                        </p>
                      </div>
                      <div className="col-span-1">
                        <h1 className="font-[500] text-[16px] leading-[24px]">
                          Service Rendered
                        </h1>
                        <p className="font-[700] text-[18px] leading-[30px]">
                          {item?.services_rendered}
                        </p>
                      </div>
                    </div>
                  </Panel>
                </div>
                <div className="grid md:grid-cols-5 grid-cols-1 gap-10 px-2 py-5 my-10">
                  <div className="col-span-full md:col-span-3">
                    <Panel>
                      <div className="grid md:grid-cols-2 min-h-[100px] grid-cols-1 gap-10">
                        <div className="col-span-1">
                          <h1 className="font-[500] text-[16px] leading-[24px]">
                            Bank Statement
                          </h1>
                          <p className="font-[700] text-[18px] leading-[30px]">
                            {item?.bank_statement}
                          </p>
                        </div>
                      </div>
                    </Panel>
                  </div>
                  <div className="col-span-full md:col-span-2 rounded-[8px] min-h-[100px] bg-center bg-no-repeat flex items-center justify-center shadow-md bg-gray-300">
                    <a
                      href={item?.bank_statement_path}
                      className="px-2 py-1 bg-white rounded-[8px]"
                    >
                      View
                    </a>
                  </div>
                </div>
                <div className="grid md:grid-cols-5 grid-cols-1 gap-10 px-2 py-5 my-10">
                  <div className="col-span-full md:col-span-3  h-full">
                    <Panel>
                      <div className="grid md:grid-cols-2 min-h-[100px] grid-cols-1 gap-10">
                        <div className="col-span-1">
                          <h1 className="font-[500] text-[16px] leading-[24px]">
                            Mandate Letter
                          </h1>
                          {/* <p className="font-[700] text-[18px] leading-[30px]">{item?.mandate_letter_path}</p> */}
                        </div>
                      </div>
                    </Panel>
                  </div>
                  <div className="col-span-full md:col-span-2 min-h-[100px] rounded-[8px] bg-center bg-no-repeat flex items-center justify-center shadow-md bg-gray-300">
                    <a
                      href={item?.bank_statement_path}
                      className="px-2 py-1 bg-white rounded-[8px]"
                    >
                      View
                    </a>
                  </div>
                </div>
                {item?.recent_invoice_paths?.map((v, i) => (
                  <div
                    className="grid md:grid-cols-5 grid-cols-1 gap-10 px-2 py-5 my-10"
                    key={i}
                  >
                    <div className="col-span-1 md:col-span-3">
                      <Panel>
                        <div className="grid md:grid-cols-2 min-h-[100px] grid-cols-1 gap-10">
                          <div className="col-span-1">
                            <h1 className="font-[500] text-[16px] leading-[24px]">
                              Recent Invoice
                            </h1>
                            {/* <p className="font-[700] text-[18px] leading-[30px]">{v}</p> */}
                          </div>
                        </div>
                      </Panel>
                    </div>

                    <div className="col-span-full md:col-span-2 min-h-[100px] bg-center rounded-[8px] bg-no-repeat flex items-center justify-center shadow-md bg-gray-300">
                      <a href={v} className="px-2 py-1 bg-white rounded-[8px]">
                        View
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
};

export default TransactionView;
