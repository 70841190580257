import useCountUp from "../../Hooks/useCountUp";

const DashboardItem = ({icon, amount=0, comment, iconColor, increment, options = {}, interval=200}) => {

    const value = useCountUp(amount, interval, increment)

    return (
        <div>
            <div className="h-[150px] place-self-center md:w-[229.94px] bg-white rounded-[16px] shadow flex items-center justify-start flex-col text-center py-[17.6px]">
                <span className={`w-[48px] h-[48px] rounded-full text-white p-[15px] mb-[8px] ${iconColor}`}>
                    {icon({className: "w-full h-full"})}
                </span>
                <p className="font-[900] text-[20px] leading-[30px] mb-[3px] text-[#0D0F11]">
                    {new Intl.NumberFormat('en-NG', options).format(amount)}
                </p>
                <p className="font-[400] text-[12px] leading-[18px] text-[#64748b]">{comment}</p>
            </div>
        </div>
    )
}

export default DashboardItem;