import { Link } from "react-router-dom";

const Button = ({ children, className, ...props }) => (
  <Link
    className="text-sm font-bold py-5 px-10 rounded-md btn-primary tracking-white hover:bg-[#88C02C]"
    {...props}
  >
    {children}
  </Link>
);

export default Button;
