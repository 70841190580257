import Input from "../../Components/Forms/Input";
import Password from "../../Components/Forms/Password";
import Submit from "../../Components/Forms/Submit";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import HeaderLogo from "../../Components/LandingPage/HeaderLogo";
import { useEffect } from "react";
import useTitle from "../../Hooks/useTitle";
import SocialLogin from "./SocialLogin";
import Countries from "../App/Dashboard/Countries";
import Categories from "../App/Dashboard/Categories";
// import intlTelInput from "intl-tel-input"

const Register = () => {
    useTitle("Register | FedhaGap SMEs");

    const { data, Form, formAction, state, formData } = useFetcher();
    const navigate = useNavigate()

    useEffect(() => {

        if (data?.success) {
            navigate({
                pathname: "/auth/verify",
                search: "email=" + formData.get('email')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.data])

    return (
        <div className="w-full h-full pb-20">
            <HeaderLogo />

            <div className="my-10">
                <p className="text-gray-800" style={{ fontSize: '18px' }}>Already have an account <Link to="/auth/login"
                    className=" text-purple-900 font-semibold">Login</Link>
                </p>
            </div>
            <h1 className="mb-8 mt-14" style={{ fontSize: '44px', fontWeight: 800 }}>Get Started</h1>

            <SocialLogin isRegister={true}>Get Started with Google</SocialLogin>

            <div className="w-full flex items-center justify-between space-x-4 overflow-hidden"><hr className="w-full" /> <p className="uppercase inline-block text-center font-light text-gray-700">OR</p><hr className="w-full" /></div>
            <Form method="post" action={formAction}>
                <Input
                    label="First Name"
                    name="first_name"
                    normalize={true}
                    placeholder="Enter your first name"
                    error={data?.errors?.first_name}
                />
                <Input
                    label="Last Name"
                    name="last_name"
                    normalize={true}
                    placeholder="Enter your last name"
                    error={data?.errors?.last_name}
                />
                <Input
                    label="Email address"
                    name="email"
                    placeholder="Enter your email"
                    error={data?.errors?.email}
                />
                <Input
                    label="Business Name"
                    name="company_name"
                    normalize={true}
                    placeholder="Enter your business name"
                    error={data?.errors?.company_name}
                />
                <Password
                    label="password"
                    name="password"
                    placeholder="Enter new Password"
                    error={data?.errors?.password}
                />
                <Countries label="Country of Operation" name="country_of_operation" placeholder="Country" error={data?.errors?.country_of_operation} />
                <span className="inline-block mt-4"></span>
                <Categories top={true} label="Business Category" name='business_category' error={data?.errors?.business_category} />
                <Submit
                    isLoading={state !== 'idle'}
                >Get Started</Submit>
            </Form>
        </div>
    )
}

export default Register;