import http from "../Store/baseHttp";


export const JoinListService = async (v) => {
    const {request} = v;
    try {
        let response = await http.post('/user/waitlist/join', Object.fromEntries(await request.formData()))
        return response.data;
    } catch (e) {
        return e.response.data
    }
}