import { BiSearch } from "react-icons/bi";
import { useLocation, useSearchParams } from "react-router-dom";

const Search = () => {

    const [, setSearchParams] = useSearchParams({ search: "" })
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const searchAction = (e) => {
        const s = Object.fromEntries(search)
        setSearchParams({ ...s, search: e.target.value })
    }
    return (

        <div
            className={`w-full md:max-w-[291.83px] h-[40px] bg-[#e7eaee] rounded-[8px] items-center justify-start flex`}
        >
            <BiSearch className="w-[15px] h-[15px] flex-none border-none ml-[15px] mr-[6.5px]" />
            <input
                type="text"
                placeholder="search"
                className="flex-1 focus:ring-transparent bg-transparent h-full w-full border-none focus:outline-none focus:border-transparent appearance-none placeholder:text-[#64748b] font-[500] leading-[21px] text-[14px]"
                onChange={searchAction}
            />
        </div>
    )
}


export default Search;