import React from "react";
import Button from "../../Components/LandingPage/Button";
import HeroImage from "../../Assets/images/HeroImage.png";
import HeroBg from "../../Assets/images/HeroBg.png";
import HowItWork from "../../Assets/images/HowItWork.png";
import TargetOne from "../../Assets/images/TargetOne.png";
import TargetTwo from "../../Assets/images/TargetTwo.png";
import UpcomingProduct from "../../Assets/images/UpcomingProduct.png";
import InvoiceDiscounting from "../../Assets/images/InvoiceDiscounting.png";
import Cooperative from "../../Assets/images/Cooperative.png";
import TTAE from "../../Assets/images/TTAE.png";
import P2PL from "../../Assets/images/P2PL.png";
import useTitle from "../../Hooks/useTitle";

const Index = () => {
    useTitle("Welcome | FedhaGap SMEs");

    const works = [
        "Sign Up, onboard & follow all FedhaGap social media handles",
        "Close your sale and invoice your client",
        "Apply for financing on Fedhagap",
        "You receive Cash from fedhagap within 48 Hours",
    ];

    return (
        <div className="my-20">
            <div className="md:px-10">
                <header
                    style={{
                        backgroundImage: `url(${HeroBg})`,
                        backgroundPosition: "top",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                    }}
                    className="flex md:py-10 md:gap-10 md:px-10 flex-col-reverse md:flex-row gap-5"
                >
                    <div className="px-10 pt-5 pb-16 md:p-5 hero-header-text basis-1 md:basis-1/2 md:pt-40">
                        <h2 className="">
                            Access the <span>financing</span> you need to <span>restock</span>{" "}
                            your inventory or for your next <span>big transaction</span>
                        </h2>
                        <p className="text-sm mt-5 mb-10">
                            Tired of waiting for your client's long payment days? Receive
                            instant payment for your invoices or working capital to restock
                            your inventory. Fedhagap employs the use of technology to shorten
                            the receivable days for MSMEs through invoice and inventory
                            financing.
                        </p>
                        <Button to="/auth/register">Get started</Button>
                    </div>
                    <div className="px-10 pt-5 md:p-5 md:basis-1/2 basis-1 text-right">
                        <img src={HeroImage} alt="hero"/>
                    </div>
                </header>
            </div>

            <section
                style={{
                    backgroundImage: `url(${HowItWork})`,
                    backgroundPosition: "top",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
                className="text-white py-10"
                id="how-it-works"
            >
                <div className="md:px-[100px] py-20">
                    <h3
                        className="text-center"
                        style={{fontWeight: "900", fontSize: "32px", lineHeight: "37px"}}
                    >
                        How it works
                    </h3>
                    <div className="grid items-center grid-cols-2 md:grid-cols-5 justify-center flex-wrap mt-10">
                        {works.map((value, k) => (
                            <div
                                key={k}
                                className="flex md:p-5 items-center flex-col justify-center lg:justify-between space-y-5 md:w-full max-w-sm m-5 lg:m-0"
                            >
                                <div
                                    className="h-10 w-10 flex how-it-works-icon items-center justify-center  rounded-full bg-gray-600">
                                    <span>{k + 1}</span>
                                </div>
                                <p className="text-center how-it-works-text">{value}</p>
                            </div>
                        ))}
                        <div className="hidden md:block">
                            <div
                                className="flex md:p-5 items-center flex-col justify-center lg:justify-between space-y-5 md:w-full max-w-sm m-5 lg:m-0">
                                <div
                                    className="h-10 w-10 flex how-it-works-icon items-center justify-center  rounded-full bg-gray-600">
                                    <span>5</span>
                                </div>
                                <p className="text-center how-it-works-text">
                                    Your client pays us directly
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex block md:hidden justify-center items-center">
                        <div
                            className="flex md:p-5 items-center flex-col justify-center lg:justify-between space-y-5 md:w-full max-w-sm m-5 lg:m-0">
                            <div
                                className="h-10 w-10 flex how-it-works-icon items-center justify-center  rounded-full bg-gray-600">
                                <span>5</span>
                            </div>
                            <p className="text-center how-it-works-text">
                                Your client pays us directly
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" flex items-center justify-center" id="target-market">
                <div className="w-full max-w-[1728px]">
                    <h3 className="text-center font-bold text-3xl mt-20 mb-10">
                        Our Target Market
                    </h3>
                    <div className="w-full">
                        <div
                            className="flex flex-col items-center justify-center lg:grid lg:grid-cols-12 lg:px-20 mb-[72px]">
                            <div className="md:col-span-1"></div>
                            <div className="my-10 lg:h-[490px] md:col-span-5">
                                <div className="w-full lg:rounded-2xl px-10 md:px-0">
                                    <img src={TargetOne} alt="" className="lg:h-[490px]"/>
                                </div>
                            </div>
                            <div className="md:pl-14 my-10 px-10 md:p-0 md:col-span-5 target-content">
                                <h1 className="">
                                    Small and Medium Size <br/> Businesses (SMEs)
                                </h1>
                                <div className="mb-10 mt-5 flex flex-col space-y-5">
                                    <p>
                                        Small And Medium Scale Businesses (SMEs) SMEs are one of the
                                        strongest drivers of economic development, innovation and
                                        employment, and contribution to the achievement of SDG Goal
                                        8.
                                    </p>
                                    <p>
                                        Long receivable days is identified by SME owners as a
                                        critical barrier to growth for them, as it limits their
                                        ability to take on new transactions or upsell to bigger
                                        clients. Fedhagap provides that confidence you need to grow
                                        without worrying about your working capital limitations or
                                        cash trap.
                                    </p>
                                </div>
                                <Button to="/auth/register">Get Started</Button>
                            </div>
                        </div>

                        <div
                            className="flex flex-col-reverse items-center justify-center lg:grid lg:grid-cols-12 lg:px-20 mb-[72px]">
                            <div className="md:col-span-1"></div>
                            <div className="md:pr-14 md:my-10 px-10 md:p-0 md:col-span-5 target-content">
                                <h1 className="" style={{color: "#88C02C"}}>
                                    Nano and Micro Enterprises <br/> (NMEs)
                                </h1>
                                <div className="mb-10 mt-5 flex flex-col space-y-5">
                                    <p>
                                        Majority of NMEs in Nigeria are in it for survival and 66.7%
                                        of them identify start-up and working capital as the biggest
                                        obstacle to sustainability. This is precisely why FedhaGap
                                        aims to target this segment of the market with its
                                        micro-working capital finance solution to ensure their
                                        inventories are on a sustainable level.
                                    </p>
                                </div>
                                <Button to="/auth/register">Get Started</Button>
                            </div>
                            <div className="my-10 lg:h-[490px] md:col-span-5">
                                <div className="w-full lg:rounded-2xl px-10 md:px-0">
                                    <img src={TargetTwo} alt="" className="lg:h-[490px]"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className=" flex items-center justify-center"
                style={{backgroundColor: "#f3f8e9"}}
                id="products"
            >
                <div className="w-full max-w-[1728px]">
                    <div className="mt-20 mb-10 flex-center">
                        <img src={UpcomingProduct} alt=""/>
                    </div>
                    <div className="w-full md:px-10">
                        {/* Item */}
                        <div
                            className="flex flex-col-reverse items-center gap-5 justify-center lg:grid lg:grid-cols-12 lg:px-20 md:mb-[32px]">
                            <div className="md:col-span-1"></div>
                            <div className="md:my-10 my-0 lg:h-[316px] md:col-span-6">
                                <div className="w-full lg:rounded-2xl px-10 md:px-0">
                                    <img
                                        src={InvoiceDiscounting}
                                        alt=""
                                        className="lg:h-[366px]"
                                    />
                                </div>
                            </div>
                            <div className="md:p-0 md:col-span-3 w-full upcoming-content">
                                <h1 className="text-[#590DA4]">Invoice Discounting</h1>
                                {/* <div className="mb-10 mt-2 flex flex-col space-y-5">
                  <p>Coming Soon</p>
                </div> */}
                            </div>
                        </div>

                        {/* Item */}
                        <div
                            className="flex flex-col items-center justify-center lg:grid lg:grid-cols-12 lg:px-20 mb-[32px]">
                            <div className="md:col-span-1"></div>

                            <div className="px-10 mt-5 mb-4 md:mt-2 md:p-0 md:col-span-3  w-full upcoming-content">
                                <h1 className="text-[#F59E0B]">Cooperatives </h1>
                                {/* <div className="mb-10 mt-2 flex flex-col space-y-5">
                  <p>Coming Soon</p>
                </div> */}
                            </div>
                            <div className="md:my-0 lg:h-[306px] md:col-span-6">
                                <div className="w-full lg:rounded-2xl flex-right px-10 md:px-0">
                                    <img src={Cooperative} alt="" className="lg:h-[366px]"/>
                                </div>
                            </div>
                        </div>

                        {/* Item */}
                        <div
                            className="flex flex-col-reverse items-center justify-center lg:grid lg:grid-cols-12 lg:px-20 mb-[32px]">
                            <div className="md:col-span-1"></div>
                            <div className="md:my-10 lg:h-[316px] md:col-span-6">
                                <div className="w-full lg:rounded-2xl px-10 md:px-0">
                                    <img src={TTAE} alt="" className="lg:h-[366px]"/>
                                </div>
                            </div>
                            <div className=" md:p-0 pb-2 md:col-span-3 w-full upcoming-content">
                                <h1 className="text-[#364D12]">Talk To An Expert</h1>
                                {/* <div className="mb-10 mt-2 flex flex-col space-y-5">
                  <p>Coming Soon</p>
                </div> */}
                            </div>
                        </div>

                        {/* Item */}
                        <div
                            className="flex flex-col items-center justify-center lg:grid lg:grid-cols-12 lg:px-20 mb-[32px]">
                            <div className="md:col-span-1"></div>

                            <div className="pt-5 md:pt-16 md:p-0 pb-4 md:col-span-3  w-full upcoming-content">
                                <h1 className="text-[#F59E0B]">Peer-to-Peer Learning</h1>
                                {/* <div className="mb-10 mt-2 flex flex-col space-y-5">
                  <p>Added Feature</p>
                </div> */}
                            </div>
                            <div className="lg:h-[336px] md:col-span-6">
                                <div className="w-full lg:rounded-2xl flex-right text-right px-10 md:px-0">
                                    <img src={P2PL} alt="" className="lg:h-[316px]"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Index;
