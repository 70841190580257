const Switch = ({ label, yes, no, isChecked = false, ...props }) => {

    return (
        <div className="flex items-center justify-start space-x-2 font-semibold">
            <label className="inline-flex relative items-center justify-start rounded-full overflow-hidden">
                <input type="radio" value={no} defaultChecked={!isChecked} name={props.name} className='z-10 checked:bg-purple-600 appearance-none border-none bg-transparent w-4 h-4' />
                <input type="radio" value={yes} name={props.name} defaultChecked={isChecked} className='appearance-none z-10 border-none peer checked:bg-purple-600 outline-none bg-transparent w-4 h-4' />
                <div className="peer absolute top-0 left-0 w-full h-full bg-gray-200 peer-checked:bg-purple-400"></div>
            </label>
            <span>Activate</span>
        </div>
    )
}

export default Switch;