import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";
import BaseController from "./BaseController";
import UserObject from "../../DataObjects/UserObject";

class AuditLogService extends BaseController
{
    async index({request, params}) {
        const url = new URL(request.url)
        try {
            let response = await http.get('/user/audit' + url.search + "&profile_id=" + UserObject.getUser().profile_id)
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }
}

export default AuditLogService;