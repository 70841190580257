import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";
import BaseController from "./BaseController";

class FAQService extends BaseController {

    async index({ request, params }) {
        try {
            let response = await http.get('/faqs');
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }
}

export default FAQService;