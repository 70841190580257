import {
  BsFacebook,
  BsInstagram,
  BsPencil,
  BsTwitter,
  BsUpload,
  BsX,
} from "react-icons/bs";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import UserObject from "../../../DataObjects/UserObject";
import Password from "../../../Components/Forms/Password";
import Submit from "../../../Components/Forms/Submit";
import { useFetcher } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ToastContext from "../../../Routes/ToastContext";
import Input from "../../../Components/Forms/Input";
import IconInput from "../../../Components/Forms/IconInput";
import useTitle from "../../../Hooks/useTitle";

const Profile = () => {
  useTitle("Profile");

  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  const {
    first_name,
    last_name,
    phone_number,
    email,
    instagram_handle,
    twitter_handle,
    profile_photo,
    facebook_handle,
  } = UserObject.getUser();

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success && data?.data?.length <= 0) {
      // UserObject.from(data?.data).storeUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="px-[40px] py-[31.52px] flex flex-col items-start divide-y divide-gray-500 w-full">
      <div className="w-full pb-[35.16px]">
        <div className="w-full grid grid-cols-5 md:gap-x-[56px] gap-5">
          <div className="col-span-full md:col-span-2 place-self-start">
            <h2 className="font-[900] text-[24px] leading-[29px] -tracking-[0.02em]">
              Personal Details
            </h2>
            <p className="font-[400] mt-[12px] text-[14px] leading-[21px]">
              Update your personal details
            </p>
          </div>
          <div className="col-span-full md:col-span-3 place-self-start ">
            <div className="inline-flex items-center justify-start space-x-[16px]">
              <div className="bg-center bg-no-repeat flex items-center overflow-hidden justify-center bg-cover w-[56px] h-[56px] bg-gray-500 rounded-full relative">
                {profile_photo && (
                  <img
                    src={profile_photo}
                    alt="profile"
                    className="w-full h-full border-none"
                  />
                )}
                {!profile_photo && (
                  <span className="text-2xl font-semibold tracking-tighter text-white">
                    {first_name[0]}
                    {last_name[0]}
                  </span>
                )}
                <button className="bg-purple-900 absolute flex rounded items-center p-0.5 right-0 bottom-0">
                  <BsUpload className="w-3 h-3 text-white " />
                </button>
              </div>
              <p className="font-[700] text-[20px] leading-[30px] ">
                {first_name} {last_name}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-5 gap-5 md:gap-x-[56px] my-5">
          <div className="flex items-start justify-start mt-10 col-span-full md:col-span-2">
            <EditButton />
          </div>
          <div className="col-span-full md:col-span-3 place-self-start">
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
              <div className="flex flex-col items-start justify-start">
                <AiOutlineMail className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Email Address
                </p>
                <p className="font-[700] text-[18px] leading-[27px]">{email}</p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <AiOutlinePhone className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Phone Number
                </p>
                <p className="font-[700] text-[18px] leading-[27px]">
                  {phone_number}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <BsInstagram className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Instagram Handle
                </p>
                <p className="font-[700] text-[18px] leading-[27px]">
                  {instagram_handle}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <BsTwitter className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Twitter Handle
                </p>
                <p className="font-[700] text-[18px] leading-[27px]">
                  {twitter_handle}
                </p>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <BsFacebook className="w-6 h-6 text-black mb-[13px]" />
                <p className="font-[400] text-[14px] leading-[21px] text-[#64748B]">
                  Facebook Handle
                </p>
                <p className="font-[700] text-[18px] leading-[27px]">
                  {facebook_handle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-[51.51px]">
        <div className="w-full grid grid-cols-5 md:gap-x-[56px]">
          <div className="col-span-full md:col-span-2 place-self-start">
            <h2 className="font-[900] text-[24px] leading-[29px] -tracking-[0.02em]">
              Reset Password
            </h2>
            <p className="font-[400] mt-[12px] text-[14px] leading-[21px]">
              You can reset your password here
            </p>
          </div>
          <div className="w-full col-span-full md:col-span-3 place-self-start ">
            <Form method="put" className="w-2/3">
              <Password label="Current password" name="old_password" />
              <Password label="New password" name="password" />
              <Password
                label="Confirm New Password"
                name="password_confirmation"
              />
              <Submit isLoading={state !== "idle"}>Reset Password</Submit>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditButton = () => {
  const [state, setState] = useState(false);
  return (
    <>
      <button
        onClick={() => setState((v) => !v)}
        className="text-[#590DA4] flex font-semibold items-center justify-start space-x-2"
      >
        <BsPencil className="w-3 h-3 border-b border-[#590DA4]" />
        <span>Edit</span>
      </button>
      <div
        data-active={state}
        className=" data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70"
      >
        <div className="relative max-w-sm w-full bg-white py-8 rounded-[16px]">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute bg-white rounded-full -left-3 -top-3"
          >
            <BsX className="w-6 h-6" />
          </button>
          <EditModal setState={setState} />
        </div>
      </div>
    </>
  );
};

const EditModal = ({ setState }) => {
  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);
  const {
    first_name,
    last_name,
    phone_number,
    email,
    instagram_handle,
    twitter_handle,
    profile_photo,
    facebook_handle,
  } = UserObject.getUser();

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      UserObject.from(data?.data).storeUser();
      setState((v) => !v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-xl font-semibold text-left">
        Edit Personal Details
      </h1>
      <div className="w-full mt-5">
        <div className="w-full text-left">
          <div className="px-6 py-4">
            <Form method="post" encType="multipart/form-data">
              <div className="flex items-center justify-start mb-5 space-x-2">
                <div
                  className="w-[72px] h-[72px] bg-gray-600 rounded-full"
                  style={{ backgroundImage: `url(${profile_photo})` }}
                ></div>
                <label className="flex items-center justify-center py-[6px] px-[12px] rounded-[8px] border border-gray-600">
                  <input className="hidden" type="file" name="profile_photo" />
                  <span className="font-[700] text-[11px] leading-[24px]">
                    Upload New Photo
                  </span>
                </label>
              </div>
              <Input
                normalize={true}
                name="first_name"
                placeholder="Enter your first name"
                defaultValue={first_name}
                label="First Name"
                error={data?.errors?.first_name}
              />
              <Input
                normalize={true}
                name="last_name"
                placeholder="Enter your last name"
                label="Last Name"
                defaultValue={last_name}
                error={data?.errors?.last_name}
              />
              <Input
                name="email"
                label="email"
                error={data?.errors?.email}
                placeholder="Enter your email address"
                value={email}
              />
              <Input
                label="Phone Number"
                name="phone_number"
                error={data?.errors?.phone_number}
                placeholder="Enter your phone number"
                value={phone_number}
              />
              <IconInput
                name="instagram_handle"
                label="instagram handle"
                LabelIcon={BsInstagram}
                error={data?.errors?.phone_number}
                options={{
                  prefix: "@",
                  lowercase: true,
                }}
                value={instagram_handle}
              />
              <IconInput
                name="twitter_handle"
                label="twitter handle"
                LabelIcon={BsTwitter}
                error={data?.errors?.phone_number}
                options={{
                  prefix: "@",
                  lowercase: true,
                }}
                value={twitter_handle}
              />
              <IconInput
                name="facebook_handle"
                label="facebook handle"
                LabelIcon={BsFacebook}
                error={data?.errors?.phone_number}
                options={{
                  prefix: "@",
                  lowercase: true,
                }}
                value={facebook_handle}
              />
              <div className="flex items-center justify-end w-full space-x-2">
                <button
                  onClick={() => {
                    setState((v) => !v);
                  }}
                  type="reset"
                  className="py-3 w-full px-4 rounded-md tracking-wider text-base min-h-[59px] font-bold border-[#590DA4] border text-[#590DA4] hover:text-white disabled:opacity-50 hover:bg-purple-900"
                >
                  Cancel
                </button>
                <Submit type="submit" isLoading={state !== "idle"}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
