import BaseController from "./BaseController";
import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";
import axios from "axios";

class GeneralService extends BaseController {
    static async companies(search = '') {
        try {
            let response = await http.get('/companies?search=' + search)
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    static async categories(search = '') {
        try {
            let response = await http.get('/business_category?page_size=100&page_num=1&sort_by=category_name&sort_type=ASC&search=' + search)
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    static async countries(search = '') {
        const options = {
            method: 'GET',
            url: '/countries.json',
            // headers: {
            //     'X-RapidAPI-Key': '9d6e45bec9msh8b0408f4788e14bp17c980jsne724df1d731f',
            //     'X-RapidAPI-Host': 'countries-states-cities-dataset.p.rapidapi.com'
            // }
        };
        try {
            let response = await axios.request(options)
            return response;
        } catch (e) {
            return e.response;
        }
    }

    static async banks(search = '') {
        const options = {
            method: 'GET',
            url: '/banks.json',
            // headers: {
            //     'X-RapidAPI-Key': '9d6e45bec9msh8b0408f4788e14bp17c980jsne724df1d731f',
            //     'X-RapidAPI-Host': 'countries-states-cities-dataset.p.rapidapi.com'
            // }
        };
        try {
            let response = await axios.request(options)
            return response;
        } catch (e) {
            return e.response;
        }
    }

    static async invoiceNumber(search = '') {
        try {
            let response = await http.get('/invoice/list?page_size=1&sort_type=DESC')
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    static async socialLogin(data) {
        data.social_profile_id = data.sub;
        data.profile_photo_path = data.picture;
        data.first_name = data.given_name
        data.last_name = data.family_name
        try {
            let response = await http.post('/oauth2/auth', data)
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    static async complete({ request, params }) {
        let formData = Object.fromEntries(await request.formData());
        try {
            let response = await http.post('/oauth2/complete', formData)
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }
}

export default GeneralService;