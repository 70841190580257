import { useContext, useEffect, useState } from "react";
import { BsX } from "react-icons/bs";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import Submit from "../../../Components/Forms/Submit";
import UserObject from "../../../DataObjects/UserObject";
import useTitle from "../../../Hooks/useTitle";
import Logo from "../../../Assets/logo/Logo.png"
import ToastContext from "../../../Routes/ToastContext";
import { useFetcher } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import Input from "../../../Components/Forms/Input";
import { Link } from "react-router-dom";

const KYCDetails = () => {
    const [state, setState] = useState(true);
    useTitle('KYC Details');
    const [mandate, setMandate] = useState([])
    const utilityBill = { file: mandate, setFile: setMandate };
    const [recentInvoice, setRecentInvoice] = useState([])
    const cacForm = { file: recentInvoice, setFile: setRecentInvoice };
    const [bankStatement, setBankStatement] = useState([])
    const directorValidId = { file: bankStatement, setFile: setBankStatement };
    const [cacC, setCacC] = useState([])
    const cac = { file: cacC, setFile: setCacC };

    const { organization } = UserObject.getUser();
    // console.log(UserObject.getUser(), organization?.kyc_verification_status);

    return (
        <div className="flex flex-col items-start justify-start w-full divide-y divide-gray-400/50">
            <div className="w-full grid grid-cols-1 sm:grid-cols-2">
                <div className="grid grid-cols-1 p-4">
                    <h1 className="font-[900] text-[24px] leading-[27px] text-[#0D0F11] tracking-[-2%]">Business Details</h1>
                    <div>
                        <p>
                            <span className="font-[700] text-[18px] leading-[27px] mr-2">Status</span>
                            <span className={`font-[700] text-[14px] leading-[21px]  rounded-full
                            ${organization?.business_verification_status === '2' && 'text-yellow-700 bg-yellow-200'}
                            ${organization?.business_verification_status === '0' && 'text-blue-700 bg-blue-200'}
                            ${organization?.business_verification_status === '1' && 'text-green-700 bg-green-200'}
                            ${organization?.business_verification_status === '3' && 'text-red-700 bg-red-200'}
                             px-[8px] py-[2px] bg-[#FFFBEB]`}>
                                {organization?.business_verification_status === '2' && 'Pending'}
                                {organization?.business_verification_status === '0' && 'Not Started'}
                                {organization?.business_verification_status === '1' && 'Approved'}
                                {organization?.business_verification_status === '3' && 'Rejected'}
                            </span>
                        </p>
                        {/* {organization?.business_verification_status === '0' && <p className="font-[700] text-[14px] leading-[21px] mt-4">Awaiting Confirmation</p>} */}
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 p-4">
                    <div className="col-span-1">
                        <h4 className="font-[400] text-[14px] leading-[21px] text-[#64748B]">Business Name</h4>
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">{organization?.name}</p>
                    </div>
                    <div className="col-span-1">
                        <h4 className="font-[400] text-[14px] leading-[21px] text-[#64748B]">Registration Number</h4>
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">{organization?.registration_number}</p>
                    </div>
                    <div className="col-span-1">
                        <h4 className="font-[400] text-[14px] leading-[21px] text-[#64748B]">Category</h4>
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">{organization?.category}</p>
                    </div>
                    <div className="col-span-1">
                        <h4 className="font-[400] text-[14px] leading-[21px] text-[#64748B]">Email</h4>
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">{organization?.email}</p>
                    </div>
                    <div className="col-span-full">
                        <h4 className="font-[400] text-[14px] leading-[21px] text-[#64748B]">Phone Number</h4>
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">{organization?.phone_number}</p>
                    </div>
                    <div className="col-span-1">
                        <h4 className="font-[400] text-[14px] leading-[21px] text-[#64748B]">Address</h4>
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">{organization?.address}</p>
                    </div>
                    <div className="col-span-1">
                        <h4 className="font-[400] text-[14px] leading-[21px] text-[#64748B]">City</h4>
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">{organization?.city}</p>
                    </div>
                    <div className="col-span-1">
                        <h4 className="font-[400] text-[14px] leading-[21px] text-[#64748B]">State</h4>
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">{organization?.state}</p>
                    </div>
                    <div className="col-span-1">
                        <h4 className="font-[400] text-[14px] leading-[21px] text-[#64748B]">Country</h4>
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">{organization?.country}</p>
                    </div>
                </div>
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2">
                <div className="grid grid-cols-1 p-4">
                    <h1 className="font-[900] text-[24px] leading-[27px] text-[#0D0F11] tracking-[-2%]">KYC Details</h1>
                    <div>
                        <p>
                            <span className="font-[700] text-[18px] leading-[27px] mr-2">Status</span>
                            <span className={`font-[700] text-[14px] leading-[21px]  rounded-full
                            ${organization?.kyc_verification_status === '2' && 'text-yellow-700 bg-yellow-200'}
                            ${organization?.kyc_verification_status === '0' && 'text-blue-700 bg-blue-200'}
                            ${organization?.kyc_verification_status === '1' && 'text-green-700 bg-green-200'}
                            ${organization?.kyc_verification_status === '3' && 'text-red-700 bg-red-200'}
                             px-[8px] py-[2px] bg-[#FFFBEB]`}>
                                {organization?.kyc_verification_status === '2' && 'Pending'}
                                {organization?.kyc_verification_status === '0' && 'Not Started'}
                                {organization?.kyc_verification_status === '1' && 'Approved'}
                                {organization?.kyc_verification_status === '3' && 'Rejected'}
                            </span>
                        </p>
                        {organization?.kyc_verification_status === '3' && <p className="font-[700] text-[14px] leading-[21px] mt-4">Please Review your mandate</p>}
                        <div className="inline-block mt-6">
                            {organization?.kyc_verification_status === '3' && <Submit onClick={() => setState(v => !v)} >Resubmit</Submit>}
                            {organization?.kyc_verification_status === '0' && <Link className="rounded-[8px] py-[9px] px-[16px] text-white bg-[#0D0F11]" to="/dashboard" >Get Started</Link>}
                            <KYC utilityBill={utilityBill} isHidden={state} handleState={setState} directorValidId={directorValidId} cacForm={cacForm} cac={cac} />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 p-4">
                    <div className="col-span-1 py-4">
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">Valid Director ID</p>
                    </div>
                    <div className="col-span-1 py-4">
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">CAC Certificate </p>
                    </div>
                    <div className="col-span-1 py-4">
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">Valid Utility Bill</p>
                    </div>
                    <div className="col-span-1 py-4">
                        <p className="font-[700] text-[18px] leading-[27px] text-[#979797]">True Coppy of the form CAC/BN1</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


const KYCDocuments = ({ utilityBill, directorValidId, cacForm, cac, setForm, data, isHidden }) => {

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-5 md:gap-10 data-[hidden=true]:hidden" data-hidden={!isHidden}>
            <FileInput label="Director's Valid Id" name="director_id" def={directorValidId} />
            <FileInput label="Valid 3 Months Utility Bill" def={utilityBill} name="utility" />
            <FileInput label="CAC Certificate" name="cac" def={cac} />
            <Input onChange={setForm} label="BANK VERIFICATION NUMBER" name="director_bvn" error={data?.errors?.director_bvn} normalize={true} placeholder='Bank Verification Number' />
            <FileInput label="Certified True Copy of the Form CAC/BN1" def={cacForm} name="cac_form" />
        </div>
    )
}

const KYC = ({ isHidden, handleState, utilityBill, directorValidId, cacForm, cac }) => {
    const { email, phone_number, registration_number, account_number, bank_name, organization } = UserObject.getUser();
    const [state, setState] = useState('kyc');
    const { flashToast } = useContext(ToastContext);

    const { state: loading, data, submit } = useFetcher();

    const initialState = {
        business_name: organization?.name,
        registration_number: registration_number,
        category: '',
        email: email,
        address: organization?.address,
        phone_number: phone_number ?? '',
        country: "",
        state: '',
        city: '',
        bank_name: bank_name ?? '',
        account_number: account_number ?? 0,
        director_id: '',
        director_bvn: ''
    }
    const [form, setForm] = useForm(initialState)

    useEffect(() => {
        if (data) {
            if (data?.success) {
                flashToast(data?.success, data?.message)
                UserObject.getUser().refresh();
                handleState(v => !v);
            }
            if (!data?.success) {
                flashToast(data?.success, data?.message)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    const handleSubmit = (e) => {
        e.preventDefault();

        const transData = new FormData();
        transData.append('business_name', form.business_name);
        transData.append('registration_number', form.registration_number);
        transData.append('category', form.category);
        transData.append('email', form.email);
        transData.append('address', form.address);
        transData.append('phone_number', form.phone_number);
        transData.append('country', form.country);
        transData.append('state', form.state);
        transData.append('city', form.city);
        transData.append('bank_name', form.bank_name);
        transData.append('account_number', form.account_number);
        transData.append('director_id', directorValidId.file[0]);
        transData.append('cac_form', cacForm.file[0]);
        transData.append('cac', cac.file[0]);
        transData.append('utility', utilityBill.file[0]);
        transData.append('director_bvn', form.director_bvn);
        submit(transData, {
            action: 'dashboard/settings/kyc-details',
            method: 'post',
            encType: 'multipart/form-data'
        })
    }


    return (
        <div data-hidden={isHidden} className="fixed data-[hidden=true]:hidden z-[999] bg-purple-700/70 top-0 left-0 w-full h-screen overflow-y-auto flex items-start justify-center pt-20">
            <div className="relative bg-white max-w-4xl rounded-[16px] p-10 m-10 w-full">
                <button type="button" onClick={() => { handleState(v => !v) }} className="p-1 rounded-full bg-white absolute -top-3 -left-3 text-gray-800"><BsX className="w-5 h-5" /></button>
                <img alt="logo" src={Logo} className="w-[140px] h-[46px]" />
                <p className="my-5">
                    Welcome to FedhaGap <span className="font-semibold">{UserObject.getUser().fullname()}</span>!
                    Join thousands of business owners with the chance to scale through in their business ventures while solving their cash trap problems with our agile solution.

                </p>
                <h2 className="my-5">Enter your KYC Details</h2>
                <div className="w-full flex items-center justify-center ">
                    <div className="flex max-w-sm items-center justify-center space-x-3 px-6 py-6 ">
                        <button type="button" onClick={() => {
                            setState('kyc')
                        }} className="flex items-center space-x-3">
                            <div
                                data-active={state === "invoice_details"}
                                className="flex items-center rounded-full text-white data-[active=true]:bg-purple-700 justify-center w-10 h-10 bg-gray-500">
                                1
                            </div>
                            <p className="whitespace-nowrap">Business Details</p>
                        </button>
                        <hr className="w-full" />
                        <button type="button" onClick={() => {
                            setState('kyc')
                        }} className="flex items-center space-x-3">
                            <div
                                data-active={state === "kyc"}
                                className="flex items-center data-[active=true]:bg-purple-700 rounded-full text-white justify-center w-10 h-10 bg-gray-500">
                                2
                            </div>
                            <p className="whitespace-nowrap">KYC Documents</p>
                        </button>
                    </div>
                </div>
                <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
                    <div className="w-full">
                        <KYCDocuments data={data} cac={cac} utilityBill={utilityBill} isHidden={state === 'kyc'} directorValidId={directorValidId} cacForm={cacForm} setForm={setForm} />
                    </div>
                    <div className="flex items-center justify-end w-full col-span-full space-x-3">
                        <div data-hidden={!(state === 'kyc')} className="inline-flex space-x-3 data-[hidden=true]:hidden">
                            <Submit isLoading={loading !== 'idle'}>Submit</Submit>
                        </div>
                    </div>
                </form>
            </div >
        </div >
    )
}

const FileInput = ({ label, placeholder, note, def, multiple = false, ...props }) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        def.setFile(files);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files])

    return (
        <div className="inline-flex w-full items-start justify-start flex-col">
            <span className=" capitalize text-gray-700 text-base w-full text-[18px] font-[700]">{label}</span>
            <label
                className="px-4 mt-2 form-input focus:outline-none text-gray-800s tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base  font-semibold placeholder:capitalize w-full">
                <input
                    type="file"
                    className="hidden"
                    onChange={e => { setFiles(v => multiple ? [...v, ...e.target.files] : [...e.target.files]) }}
                    {...props}
                />
                <div className="flex items-center justify-between">
                    <p className="text-gray-400">{placeholder}</p>
                    <MdOutlineDriveFolderUpload className="w-6 h-6" />
                </div>
            </label>
            <p className="mt-[7.8px] font-[700] text-[14px] leading-[21px] text-[#A0ABBB]">{note}</p>
            <div className="mt-2 flex flex-col items-start justify-start w-full space-y-2">
                {files.map((v, i) => (
                    <div key={i} className="flex flex-col items-start justify-start w-full">
                        <div className="flex items-center justify-between w-full">
                            <p>{v.name}</p>
                            <button className="focus:outline-none"><BsX className="w-4 h-4" /></button>
                        </div>
                        <div className="h-2 w-full rounded-full bg-green-600">
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default KYCDetails;