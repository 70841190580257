import Logo from "../../Assets/logo/Logo.png";
import Home from "../../Assets/dashboard/Home.svg";
import Transaction from "../../Assets/dashboard/Transaction.svg";
import Invoice from "../../Assets/dashboard/Invoice.svg";
import Setting from "../../Assets/dashboard/Setting.svg";
import Help from "../../Assets/dashboard/Help.svg";
import Cooperative from "../../Assets/dashboard/Cooperative.svg";
import TalkToAnExpert from "../../Assets/dashboard/TalktoAnExpert.svg";
import CreateRequest from "../../Assets/dashboard/CreateRequest.svg";
import NavLink from "./NavLink";
import { FiX } from "react-icons/fi";
import NavDropdown from "./NavDropdown";
import UserObject from "../../DataObjects/UserObject";

const NavBar = ({ value, click }) => {
  const user = UserObject.getUser();

  return (
    <aside
      className={`lg:relative z-50 w-[250px] h-full bg-white absolute top-0 left-0 ${
        value ? "" : "hidden lg:block"
      }`}
      style={{ borderRight: "1.5px solid #E7EAEE" }}
    >
      <div className="">
        <button
          className="absolute p-1 bg-red-50 top-0 right-0 lg:hidden"
          onClick={click}
        >
          <FiX className="w-6 h-6" />
        </button>
        <div className="w-[250px] h-full">
          <img
            src={Logo}
            alt="logo"
            className="w-[145.82px] h-[48px] mx-auto mt-[31px] mb-[55.55px]"
          />
          <div className="w-full h-full flex flex-col items-start justify-start pl-[14.96px] pr-[20.42px] space-y-[20px]">
            <NavLink to="/dashboard" onClick={click} icon={Home}>
              Dashboard
            </NavLink>
            <hr className="border-t border-gray-500/50 w-full lg:hidden" />
            <NavDropdown name="Invoice" icon={Invoice}>
              <NavLink
                onClick={click}
                to="/dashboard/transaction/create"
                icon={CreateRequest}
                isKyc={user.isKYCVerified()}
              >
                Create Request
              </NavLink>
              <NavLink
                onClick={click}
                to="/dashboard/transaction"
                icon={Transaction}
                isKyc={user.isKYCVerified()}
              >
                Transaction history
              </NavLink>
              <NavLink
                onClick={click}
                to="/dashboard/invoice"
                icon={Invoice}
                isKyc={user.isKYCVerified()}
              >
                Generate Invoice
              </NavLink>
            </NavDropdown>
            <NavDropdown name="Cooperative" icon={Cooperative}>
              <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/transaction-history-create"
                icon={Invoice}
              >
                Cooperative Dashboard
              </NavLink>
              <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/transaction-history"
                icon={Transaction}
              >
                Loan Request
              </NavLink>
              <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/invoice"
                icon={Invoice}
              >
                Pay Contribution
              </NavLink>
            </NavDropdown>
            <NavDropdown name="Talk to an Expert" icon={TalkToAnExpert}>
              <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/transaction-history-create"
                icon={Invoice}
              >
                Home
              </NavLink>
              <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/transaction-history"
                icon={Transaction}
              >
                Consultation history
              </NavLink>
              <NavLink
                onClick={click}
                isComingSoon={true}
                to="/dashboard/invoice"
                icon={Invoice}
              >
                Generate Invoice
              </NavLink>
            </NavDropdown>
            <NavDropdown name="Settings" icon={Setting}>
              <NavLink onClick={click} to="/dashboard/settings" icon={Setting}>
                User Settings
              </NavLink>
              <NavLink
                onClick={click}
                to="/dashboard/help-and-support"
                icon={Help}
              >
                Help & Support
              </NavLink>
            </NavDropdown>
            {/* <NavLink onClick={click} to="/dashboard/audit-log" icon={AuditLog}>
              Audit Log
            </NavLink> */}
            {/* <NavLink onClick={click} to="#" icon={Apply}>
              Apply To Join Cooperative
            </NavLink> */}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default NavBar;
