import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../../Hooks/useClickOutside";
import GeneralService from "../../../Services/Dashboard/GeneralService";
import InvoiceInput from "../Invoice/InvoiceInput";

const Countries = ({ setStates = () => {}, placeholder, ...props }) => {
  useEffect(() => {}, []);

  return (
    <>
      <CustomSelect
        className="w-full mt-2 mb-5"
        setStates={setStates}
        {...props}
        placeholder={placeholder}
      />
    </>
  );
};

const CustomSelect = ({ setStates, placeholder, ...props }) => {
  const [search, setSearch] = useState("nigeria");
  const [field, setField] = useState(null);
  const [data, setData] = useState([]);
  const details = useRef();
  const check = useRef();

  const loadOptions = (inputValue = "") => {
    GeneralService.countries(inputValue).then((response) => {
      let data = response?.data?.data?.result.filter(
        (v) => v.name.toLowerCase() === "nigeria"
      );
      setData(data ?? []);
    });
  };

  useClickOutside(details, () => {
    details.current.removeAttribute("open");
  });

  useEffect(() => {
    loadOptions(search);
  }, [search]);

  return (
    <div>
      <label
        className="w-full mb-1 text-base text-gray-700 capitalize"
        style={{ fontWeight: 700, fontSize: "18px" }}
      >
        {props.label}
      </label>
      <details
        className="relative text-[12px] leading-[18px] font-[700]"
        ref={details}
      >
        <summary className="w-full px-4 py-5 text-sm font-semibold tracking-wide text-gray-500 list-none bg-transparent border form-input focus:outline-none focus:border-purple-800 placeholder:text-gray-400 placeholder:capitalize">
          {field ?? placeholder ?? "Select Country"}
        </summary>
        <ul className="absolute z-40 w-full mb-2 bg-white shadow-lg">
          <li className="px-6">
            <InvoiceInput
              normalize={true}
              placeholder="Search for company"
              type="text"
              onChange={(e) => {
                setSearch(e.target.value);
                setField(null);
                check.current.checked = true;
              }}
              value={search}
            />
            <input
              type="radio"
              ref={check}
              {...props}
              className="hidden"
              value=""
            />
          </li>
          {data.map((v, i) => (
            <li key={i} className="w-full">
              <label
                onClick={() => {
                  setField(v.name);
                  details.current.removeAttribute("open");
                  setStates(v.states);
                }}
              >
                <input
                  type="radio"
                  {...props}
                  className="hidden peer"
                  value={v.name}
                  defaultChecked={true}
                />
                <span className="inline-block w-full px-6 py-2 text-black cursor-pointer peer-checked:bg-blue-700 peer-checked:text-white hover:bg-blue-500 hover:text-white">
                  {v.emoji} {v.name}
                </span>
              </label>
            </li>
          ))}
          {data.length <= 0 && (
            <li className="inline-block px-6 py-2 text-black cursor-pointer">
              No Data Available yet
            </li>
          )}
        </ul>
      </details>
    </div>
  );
};

export default Countries;
