import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../../Hooks/useClickOutside";
import GeneralService from "../../../Services/Dashboard/GeneralService";
import InvoiceInput from "../Invoice/InvoiceInput";

const Categories = ({ placeholder, ...props }) => {
  return (
    <div className="w-full mt-1 mb-3">
      <CustomSelect
        className="mt-2 mb-5 -full"
        {...props}
        placeholder={placeholder}
      />
    </div>
  );
};

const CustomSelect = ({ top = false, placeholder, ...props }) => {
  const [search, setSearch] = useState();
  const [field, setField] = useState(null);
  const [data, setData] = useState([]);
  const details = useRef();
  const check = useRef();
  const loadOptions = (inputValue) => {
    GeneralService.categories(inputValue).then((response) =>
      setData(response?.data?.data ?? [])
    );
  };

  useClickOutside(details, () => {
    details.current.removeAttribute("open");
  });

  useEffect(() => {
    loadOptions(search);
  }, [search]);

  const isTop = top ? "bottom-0 mb-16" : "mb-2 ";

  return (
    <div>
      <label
        className="w-full mb-1 text-base text-gray-700 capitalize"
        style={{ fontWeight: 700, fontSize: "18px" }}
      >
        {props.label}
      </label>
      <details
        className="relative text-[12px] leading-[18px] font-[700]"
        ref={details}
      >
        <summary className="w-full px-4 py-5 text-sm font-semibold tracking-wide text-gray-500 list-none bg-transparent border form-input focus:outline-none focus:border-purple-800 placeholder:text-gray-200 placeholder:capitalize">
          {field ?? placeholder ?? "Search for business category"}
        </summary>
        <ul
          className={`absolute  w-full  shadow-lg bg-white z-40 max-h-80 overflow-y-auto ${isTop}`}
        >
          <li className="sticky top-0 px-6">
            <InvoiceInput
              normalize={true}
              placeholder={placeholder}
              type="text"
              onChange={(e) => {
                setSearch(e.target.value);
                setField(null);
                check.current.checked = true;
              }}
              value={search}
            />
            <input
              type="radio"
              ref={check}
              {...props}
              className="hidden"
              value=""
            />
          </li>
          {data.map((v, i) => (
            <li key={i} className="w-full">
              <label
                onClick={() => {
                  setField(v.category_name);
                  details.current.removeAttribute("open");
                }}
              >
                <input
                  type="radio"
                  {...props}
                  className="hidden peer"
                  value={v.category_name}
                />
                <span className="inline-block w-full px-6 py-2 text-black cursor-pointer peer-checked:bg-blue-700 peer-checked:text-white hover:bg-blue-500 hover:text-white">
                  {v.category_name}
                </span>
              </label>
            </li>
          ))}
          {data.length <= 0 && (
            <li className="inline-block px-6 py-2 text-black cursor-pointer">
              No Data Available yet
            </li>
          )}
        </ul>
      </details>
    </div>
  );
};
export default Categories;
