import ResponseObject from "../../DataObjects/ResponseObject";
import http from "../../Store/baseHttp";
import BaseController from "./BaseController";

class SupportService extends BaseController {

    async index({ request, params }) {
        try {
            let response = await http.get('/support?sort_type=DESC');
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }

    async post({ request, params }) {
        let formData = Object.fromEntries(await request.formData());
        try {
            let response = await http.post('/support', formData);
            response = ResponseObject.from(response)
            return response;
        } catch (e) {
            return ResponseObject.from(e?.response)
        }
    }
}

export default SupportService;