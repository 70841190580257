import useModal from "../../../Hooks/useModal";
import { BsCheck, BsX } from "react-icons/bs";
import { useContext } from "react";
import ToastContext from "../../../Routes/ToastContext";

const Toast = () => {

    const { toast: { isSuccess, message }, clearToast } = useContext(ToastContext);

    const { Wrapper, Body, Footer } = useModal(false);

    if (!message) {
        return (<></>)
    }
    return (
        <Wrapper zIndex="z-[999999]">
            <Body>
                <div className="w-full flex text-center flex-col pt-6 space-y-2 items-center justify-center">
                    <div className={`w-40 h-40 border-4 p-2 rounded-full ${isSuccess ? 'border-green-500' : 'border-red-500'}`}>
                        {
                            isSuccess
                                ? <BsCheck
                                    className={`w-full h-full text-green-500 transform transition-all ease-linear duration-1000`} />
                                :
                                <BsX
                                    className={`w-full h-full text-red-500 transform transition-all ease-linear duration-1000`} />
                        }
                    </div>
                    <h3 className="text-3xl font-bold text-gray-800">{isSuccess ? 'Successful' : 'Error'}</h3>
                    <p className="text-semibold text-lg text-gray-600 tracking-wide">{message}</p>
                </div>
            </Body>
            <Footer>
                <div className="w-full flex items-center justify-center py-4">
                    <button onClick={clearToast}
                        className={`w-1/2 px-6 py-2 ${isSuccess ? 'bg-green-600' : 'bg-red-600'} rounded-full text-sm tracking-wide text-white`}>
                        {isSuccess ? 'Okay' : 'Try Again'}
                    </button>
                </div>
            </Footer>
        </Wrapper>
    )
}

export default Toast;