import PageHeader from "../../../Components/Dashboard/PageHeader";
import Panel from "../../../Components/Dashboard/Panel";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Settings = () => {

    const location = useLocation();

    return (
        <div className="w-full">
            <PageHeader>Settings</PageHeader>
            <div id='portal' className="w-full flex items-center justify-end"></div>
            <div
                className="mt-[32px] mb-[16px] bg-white px-[18.06px] overflow-x-auto rounded-[16px] space-x-[18.06px] flex items-center justify-start">
                <Link to="/dashboard/settings"
                    className="border-box focus:outline-none text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
                    data-active={location.pathname === "/dashboard/settings"}
                >
                    Profile
                </Link>
                <Link to="/dashboard/settings/members"
                    className="border-box focus:outline-none text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
                    data-active={location.pathname === "/dashboard/settings/members"}
                >
                    Members
                </Link>
                <Link to="/dashboard/settings/audit-logs"
                    className="border-box focus:outline-none whitespace-nowrap text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
                    data-active={location.pathname === "/dashboard/settings/audit-logs"}
                >
                    Audit Logs
                </Link>
                <Link to="/dashboard/settings/kyc-details"
                    className="border-box focus:outline-none whitespace-nowrap text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
                    data-active={location.pathname === "/dashboard/settings/kyc-details"}
                >
                    Organization & KYC Details
                </Link>

            </div>
            <Panel>
                <Outlet />
            </Panel>
        </div>
    )
}

export default Settings;