import { DatePicker } from "antd"
import { useLocation, useSearchParams } from "react-router-dom"

const DateRange = () => {
    const [, setSearchParams] = useSearchParams({ dateFrom: "", dateTo: "" })
    const location = useLocation();
    const search = new URLSearchParams(location.search)
    const handleDateChange = (dateFrom, dateTo) => {
        const s = Object.fromEntries(search)
        setSearchParams({ ...s, dateFrom: dateFrom, dateTo: dateTo })
    }

    return (
        <DatePicker.RangePicker className="py-2 w-full md:max-w-[291.83px] px-4 bg-white border-gray-300/50"
            onCalendarChange={(dates, dateString) => {
                const [dateFrom, dateTo] = dateString;
                handleDateChange(dateFrom, dateTo);
            }} />
    )
}

export default DateRange;