/* eslint-disable react-hooks/exhaustive-deps */
import Input from "../../Components/Forms/Input";
import Password from "../../Components/Forms/Password";
import Submit from "../../Components/Forms/Submit";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import AuthContext from "../../Routes/AuthContext";
import HeaderLogo from "../../Components/LandingPage/HeaderLogo";
import useTitle from "../../Hooks/useTitle";
import SocialLogin from "./SocialLogin";

const Login = () => {
  useTitle("Login | FedhaGap SMEs");
  const { formAction, Form, state, data, formData } = useFetcher();
  const { storeUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.success) {
      storeUser(data?.data);
      navigate({
        pathname: "/dashboard",
        search: "email=" + formData.get("email"),
      });
    }
  }, [data?.data]);
  // const { formAction, Form, state, data} = useFetcher();

  return (
    <div className="w-full h-full mt-16 md:mt-20">
      <HeaderLogo />

      <p className="mt-10 mb-10 font-semibold text-gray-800">
        Don't have an account?{" "}
        <Link to="/auth/register" className="font-bold text-purple-900">
          Get Started
        </Link>{" "}
      </p>
      <h1 className="mt-10 mb-10" style={{ fontSize: "44px", fontWeight: 800 }}>
        Login
      </h1>
      <Form action={formAction} method="post">
        <Input
          label="Email Address"
          name="email"
          placeholder="Enter your email"
          error={data?.errors?.email}
        />
        <Password
          label="password"
          name="password"
          placeholder="enter your password"
          error={data?.errors?.password}
        />
        <div className="mb-8 text-right" style={{ marginTop: "-15px" }}>
          <Link to="/auth/forgot-password">
            {" "}
            <span
              style={{ color: "#590DA4", fontSize: "18px", fontWeight: 900 }}
            >
              {" "}
              Forgot Password?{" "}
            </span>
          </Link>
        </div>

        <p className="mb-2 text-sm text-red-700">{data?.message}</p>

        <Submit type="submit" isLoading={state !== "idle"}>
          Login
        </Submit>
        <div className="flex items-center justify-between w-full mt-8 space-x-4 overflow-hidden">
          <hr className="w-full" />{" "}
          <p className="inline-block font-light text-center text-gray-700 uppercase">
            OR
          </p>
          <hr className="w-full" />
        </div>

        <div>
          <SocialLogin>Login With Google</SocialLogin>
        </div>
      </Form>
    </div>
  );
};

export default Login;
