import Cleave from "cleave.js/dist/cleave-react";

const IconInput = ({ label, icon, error, normalize, LabelIcon, ...props }) => {
    return (
        <div className="flex flex-col justify-start items-start relative mb-5 max-h-fit">
            <label className="mb-1 capitalize text-gray-700 text-base w-full" style={{ fontWeight: 700, fontSize: '18px' }}>
                {label}
            </label>
            <div className="flex items-center mt-1 justify-start w-full border-none">
                {
                    normalize ? (
                        <input
                            className="pl-12 pr-4 form-input text-right focus:outline-none text-sm text-gray-800 font-bold tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold placeholder:capitalize w-full"
                            {...props}
                        />
                    ) : (
                        <Cleave
                            className="pl-12 pr-4 form-input text-right focus:outline-none text-sm text-gray-800 font-bold tracking-wide border bg-transparent focus:border-purple-800 py-4 text-base placeholder:text-gray-400 font-semibold placeholder:capitalize w-full"
                            {...props}
                        />
                    )
                }
                {
                    icon && (
                        <div className="absolute right-0 mx-2 bg-white pl-2 pr-1">
                            {icon}
                        </div>
                    )
                }
                <LabelIcon className="absolute ml-2 w-8 h-8 text-gray-700/70" />
            </div>
            <div
                className={`text-red-500 text-sm font-semibold tracking-wide ${error ? '' : 'text-transparent'}`}
            >
                {Array.isArray(error) && error.length > 1 && (
                    <ul className="list-disc ml-5">
                        {error.map((e, k) => (
                            <li key={k} >{e}</li>
                        ))}
                    </ul>
                )}
                {Array.isArray(error) && error.length === 1 && error}
            </div>
        </div>
    )
};

export default IconInput;