import useTitle from "../../../Hooks/useTitle";
import useTable from "../../../Components/Dashboard/Table/useTable";
import Panel from "../../../Components/Dashboard/Panel";
import PaidStatus from "../../../Components/Dashboard/PaidStatus";
import PageHeader from "../../../Components/Dashboard/PageHeader";
import {
  Await,
  Link,
  Outlet,
  useFetcher,
  useLoaderData,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import MoneyFormat from "../../../Components/Utils/MoneyFormat";
import { DatePicker } from "antd";
import { Suspense, useContext, useEffect, useState } from "react";
import Invoice from "../../../Assets/icons/invoice.png";
import UserObject from "../../../DataObjects/UserObject";
import Logo from "../../../Assets/logo/Logo.png";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import { BsX } from "react-icons/bs";
import Input from "../../../Components/Forms/Input";
import Submit from "../../../Components/Forms/Submit";
import ToastContext from "../../../Routes/ToastContext";
import useForm from "../../../Hooks/useForm";
import Categories from "./Categories";
import Countries from "./Countries";
import States from "./States";
import Banks from "./Banks";

const DashboardIndex = () => {
  useTitle("Dashboard");
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const data = useLoaderData();

  const [, setSearchParams] = useSearchParams({ dateFrom: "", dateTo: "" });
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const [mandate, setMandate] = useState([]);
  const utilityBill = { file: mandate, setFile: setMandate };
  const [recentInvoice, setRecentInvoice] = useState([]);
  const cacForm = { file: recentInvoice, setFile: setRecentInvoice };
  const [bankStatement, setBankStatement] = useState([]);
  const directorValidId = { file: bankStatement, setFile: setBankStatement };
  const [cacC, setCacC] = useState([]);
  const cac = { file: cacC, setFile: setCacC };

  const handleDateChange = (dateFrom, dateTo) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, dateFrom: dateFrom, dateTo: dateTo });
  };

  useEffect(() => {
    if (UserObject.getUser().organization?.kyc_verification_status !== "1") {
      UserObject.getUser().refresh();
    }
  }, []);

  return (
    <div className="w-full h-full pb-32">
      <div className="flex flex-col items-start justify-between mb-5 sm:flex-row">
        <div className="mb-10">
          <PageHeader>Dashboard</PageHeader>
          <span className="mt-3 font-[500] text-[16px] leading-[24px]">
            {new Date().toDateString()}
          </span>
        </div>
        <div className="w-60">
          <DatePicker.RangePicker
            className="px-4 py-2 bg-white border-gray-300/50"
            onCalendarChange={(dates, dateString) => {
              const [dateFrom, dateTo] = dateString;
              handleDateChange(dateFrom, dateTo);
            }}
          />
        </div>
      </div>
      <Suspense fallback={<div>Loading</div>}>
        <Await resolve={data?.data}>
          {(dt) => {
            if (
              // dt?.data?.data?.length <= 0 ||
              !UserObject.getUser()?.isKYCVerified()
            ) {
              return (
                <EmptyState
                  cacForm={cacForm}
                  utilityBill={utilityBill}
                  directorValidId={directorValidId}
                  cac={cac}
                />
              );
            }
            return (
              <>
                <Outlet />
                <PageHeader>Transaction History</PageHeader>
                <Panel>
                  <Table>
                    <RowHeader>
                      <ColumnHeader>Company</ColumnHeader>
                      <ColumnHeader>Date Created</ColumnHeader>
                      <ColumnHeader>Fund Status</ColumnHeader>
                      <ColumnHeader>
                        <p className="text-right">Amount</p>
                      </ColumnHeader>
                      <ColumnHeader>Action</ColumnHeader>
                    </RowHeader>
                    <Body data={data}>
                      {(data) => {
                        return data?.data?.data?.map((v, i) => {
                          return (
                            <Row key={i}>
                              <Column>{v.company.company_name}</Column>
                              <Column>
                                {new Date(v.created_at).toDateString()}
                              </Column>
                              <Column>
                                <PaidStatus value={v.status} />
                              </Column>
                              <Column>
                                <div className="text-right">
                                  ₦{MoneyFormat(v.amount)}
                                </div>
                              </Column>
                              <Column>
                                <Link
                                  to={
                                    `/dashboard/transaction/` + v.request_token
                                  }
                                  className="text-[#88C02C] cursor-pointer"
                                >
                                  View More
                                </Link>
                              </Column>
                            </Row>
                          );
                        });
                      }}
                    </Body>
                  </Table>
                </Panel>
              </>
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
};

const EmptyState = ({ utilityBill, cacForm, directorValidId, cac }) => {
  const [state, setState] = useState(true);
  const { organization } = UserObject.getUser();
  const [reKyx, setReKyc] = useState(null);

  return (
    <div className="rounded-[16px] bg-white p-6 flex items-center justify-center h-full w-full relative bottom-0 top-0 ">
      <div className="flex flex-col items-center justify-center w-full max-w-sm">
        {organization?.kyc_verification_status === "0" && (
          <img
            src={Invoice}
            alt="invoice"
            className="mb-[28.36px] aspect-auto "
          />
        )}
        {organization?.kyc_verification_status === "2" && (
          <svg
            width="78"
            height="78"
            viewBox="0 0 78 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M65.8125 29.25V56.0625H26.8125V7.3125H43.875V20.7188C43.875 25.428 47.697 29.25 52.4062 29.25H65.8125ZM51.1875 7.90238L65.2226 21.9375H52.4062C52.083 21.9375 51.773 21.8091 51.5445 21.5805C51.3159 21.352 51.1875 21.042 51.1875 20.7188V7.90238ZM24.375 0C23.0821 0 21.8421 0.513615 20.9279 1.42785C20.0136 2.34209 19.5 3.58207 19.5 4.875V14.625H10.9688C7.605 14.625 4.875 17.355 4.875 20.7188V71.9062C4.875 75.27 7.605 78 10.9688 78H51.1875C54.5513 78 57.2812 75.27 57.2812 71.9062V63.765C57.2816 63.6347 57.2751 63.5046 57.2618 63.375H68.25C69.5429 63.375 70.7829 62.8614 71.6971 61.9471C72.6114 61.0329 73.125 59.7929 73.125 58.5V21.5182C73.1247 20.2254 72.6109 18.9857 71.6966 18.0716L55.0534 1.42838C54.1394 0.514072 52.8996 0.000276107 51.6068 0H24.375ZM19.5 58.5V21.9375H12.1875V70.6875H49.9688V63.765C49.9688 63.6334 49.9736 63.5018 49.9882 63.375H24.375C23.0821 63.375 21.8421 62.8614 20.9279 61.9471C20.0136 61.0329 19.5 59.7929 19.5 58.5Z"
              fill="black"
            />
          </svg>
        )}
        {organization?.kyc_verification_status === "3" && (
          <svg
            width="78"
            height="78"
            viewBox="0 0 78 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39 6.5C56.9725 6.5 71.5 21.0275 71.5 39C71.5 56.9725 56.9725 71.5 39 71.5C21.0275 71.5 6.5 56.9725 6.5 39C6.5 21.0275 21.0275 6.5 39 6.5ZM50.6675 22.75L39 34.4175L27.3325 22.75L22.75 27.3325L34.4175 39L22.75 50.6675L27.3325 55.25L39 43.5825L50.6675 55.25L55.25 50.6675L43.5825 39L55.25 27.3325L50.6675 22.75Z"
              fill="#FD0D1B"
            />
          </svg>
        )}

        <h3 className="text-[#590DA4] font-[700] text-[16px] leading-[24px] mt-[25px] ">
          Dear {UserObject.getUser()?.first_name}{" "}
          {UserObject.getUser()?.last_name}...
        </h3>
        {organization?.kyc_verification_status === "0" && (
          <p className="mb-[29.09px] text-center font-[700] text-[14px] leading-[21px]">
            You are a few steps away from logging your first request, please
            click here to complete your Corporate KYC registration.
          </p>
        )}
        {/* {organization?.kyc_verification_status === "2" ||
          (organization?.kyc_verification_status === "3" && (
            <p className="mb-[15.09px] text-center font-[700] text-[14px] leading-[21px]">
              Your corporate KYC Verification status is:
            </p>
          ))} */}
        {organization?.kyc_verification_status === "0" && (
          <button
            onClick={() => setState((v) => !v)}
            className="rounded-[8px] py-[9px] px-[16px] text-white bg-[#0D0F11]"
          >
            + Start Your KYC
          </button>
        )}
        {organization?.kyc_verification_status === "2" && (
          <>
            <p className="mb-[15.09px] text-center font-[700] text-[14px] leading-[21px]">
              Your corporate KYC Verification status is:
            </p>
            <span className="px-3 py-1 text-sm text-yellow-700 bg-yellow-200 rounded-full">
              Pending
            </span>
          </>
        )}
        {organization?.kyc_verification_status === "3" && (
          <>
            <p className="mb-[15.09px] text-center font-[700] text-[14px] leading-[21px]">
              Your corporate KYC Verification status is:
            </p>
            <span className="px-3 py-1 text-sm text-red-700 bg-red-200 rounded-full">
              Rejected
            </span>
            <p className="mt-2 mb-6 text-sm">
              {organization.comments[0]?.comments ?? ""}
            </p>
            <button
              type="button"
              onClick={() => setReKyc(UserObject.getUser()?.organization)}
              className="rounded-[8px] py-[9px] mt-2 px-[16px] text-white bg-[#0D0F11]"
            >
              Re-Submit KYC
            </button>
          </>
        )}
        <KYC
          utilityBill={utilityBill}
          isHidden={state}
          handleState={setState}
          directorValidId={directorValidId}
          cacForm={cacForm}
          cac={cac}
        />
        <ReSubmitKYC v={reKyx} setV={setReKyc} />
      </div>
    </div>
  );
};

const KYC = ({
  isHidden,
  handleState,
  utilityBill,
  directorValidId,
  cacForm,
  cac,
}) => {
  const [state, setState] = useState("bus");
  const { flashToast } = useContext(ToastContext);
  const { email, phone_number, organization } = UserObject.getUser();

  const { state: loading, data, submit } = useFetcher();

  const initialState = {
    business_name: organization?.name,
    registration_number: organization?.registration_number,
    category: organization?.category,
    email: email,
    address: organization?.address,
    phone_number: phone_number,
    country: organization?.country,
    state: organization?.state,
    city: organization?.city,
    bank_name: organization?.bank_name,
    account_number: organization?.account_number ?? 0,
    director_id: "",
    director_bvn: organization?.director_bvn,
  };
  const [form, setForm] = useForm(initialState);

  useEffect(() => {
    if (data) {
      if (data?.success) {
        flashToast(data?.success, data?.message);
        UserObject.getUser().refresh();
        handleState((v) => !v);
      }
      if (!data?.success) {
        flashToast(data?.success, data?.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const transData = new FormData();
    transData.append("business_name", form.business_name);
    transData.append("registration_number", form.registration_number);
    transData.append("category", form.category);
    transData.append("email", form.email);
    transData.append("address", form.address);
    transData.append("phone_number", form.phone_number);
    transData.append("country", form.country);
    transData.append("state", form.state);
    transData.append("city", form.city);
    transData.append("bank_name", form.bank_name);
    transData.append("account_number", form.account_number);
    transData.append("director_id", directorValidId.file[0]);
    transData.append("cac_form", cacForm.file[0]);
    transData.append("cac", cac.file[0]);
    transData.append("utility", utilityBill.file[0]);
    transData.append("director_bvn", form.director_bvn);
    submit(transData, {
      action: "dashboard",
      method: "post",
      encType: "multipart/form-data",
    });
  };

  return (
    <div
      data-hidden={isHidden}
      className="fixed data-[hidden=true]:hidden z-[999] bg-purple-700/70 top-0 left-0 w-full h-screen overflow-y-auto flex items-start justify-center pt-20"
    >
      <div className="relative bg-white max-w-4xl rounded-[16px] p-10 m-10 w-full">
        <button
          type="button"
          onClick={() => {
            handleState((v) => !v);
          }}
          className="absolute p-1 text-gray-800 bg-white rounded-full -top-3 -left-3"
        >
          <BsX className="w-5 h-5" />
        </button>
        <img alt="logo" src={Logo} className="w-[140px] h-[46px]" />
        <p className="my-5">
          Welcome to FedhaGap{" "}
          <span className="font-semibold">
            {UserObject.getUser().fullname()}
          </span>
          ! Join thousands of business owners with the chance to scale through
          in their business ventures while solving their cash trap problems with
          our agile solution.
        </p>
        <h2 className="my-5">Enter your KYC Details</h2>
        <div className="flex items-center justify-center w-full ">
          <div className="flex items-center justify-center max-w-sm px-6 py-6 space-x-3 ">
            <button
              type="button"
              onClick={() => {
                setState("bus");
              }}
              className="flex items-center space-x-3"
            >
              <div
                data-active={state === "invoice_details"}
                className="flex items-center rounded-full text-white data-[active=true]:bg-purple-700 justify-center w-10 h-10 bg-gray-500"
              >
                1
              </div>
              <p className="whitespace-nowrap">Business Details</p>
            </button>
            <hr className="w-full" />
            <button
              type="button"
              onClick={() => {
                setState("kyc");
              }}
              className="flex items-center space-x-3"
            >
              <div
                data-active={state === "kyc"}
                className="flex items-center data-[active=true]:bg-purple-700 rounded-full text-white justify-center w-10 h-10 bg-gray-500"
              >
                2
              </div>
              <p className="whitespace-nowrap">KYC Documents</p>
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          method="post"
          encType="multipart/form-data"
        >
          <div className="w-full">
            <BusinessDetails
              data={data}
              setForm={setForm}
              isHidden={state === "bus"}
            />
            <KYCDocuments
              data={data}
              cac={cac}
              utilityBill={utilityBill}
              isHidden={state === "kyc"}
              directorValidId={directorValidId}
              cacForm={cacForm}
              setForm={setForm}
            />
          </div>
          <div className="flex items-center justify-end w-full space-x-3 col-span-full">
            <div
              data-hidden={!(state === "bus")}
              className="inline-flex space-x-3 data-[hidden=true]:hidden"
            >
              <button
                type="reset"
                onClick={() => {
                  handleState((v) => !v);
                }}
                className="px-10 py-5 text-sm font-bold text-purple-700 border border-purple-700 rounded-md "
              >
                Cancel
              </button>
              <Submit
                type="button"
                onClick={(e) => {
                  setState("kyc");
                }}
              >
                Continue
              </Submit>
            </div>
            <div
              data-hidden={!(state === "kyc")}
              className="inline-flex space-x-3 data-[hidden=true]:hidden"
            >
              <button
                type="button"
                onClick={() => {
                  setState("bus");
                }}
                className="px-10 py-5 text-sm font-bold tracking-wide text-purple-700 border border-purple-700 rounded-md "
              >
                Previous
              </button>
              <Submit isLoading={loading !== "idle"}>Submit</Submit>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const BusinessDetails = ({ data, setForm, isHidden }) => {
  const { email, phone_number, organization } = UserObject.getUser();
  const [states, setStates] = useState([]);

  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-2 w-full gap-5 md:gap-10 data-[hidden=true]:hidden"
      data-hidden={!isHidden}
    >
      <Input
        onChange={setForm}
        label="Business Name"
        name="business_name"
        normalize={true}
        placeholder="Business Name"
        defaultValue={organization?.name}
        error={data?.errors?.business_name}
      />
      <Input
        onChange={setForm}
        label="Registration Number"
        name="registration_number"
        normalize={true}
        placeholder="Registration Number"
        defaultValue={organization?.registration_number}
        error={data?.errors?.registration_number}
      />
      <Categories
        onChange={setForm}
        label="Business Category"
        name="category"
        placeholder={organization?.category}
        error={data?.errors?.category}
      />
      <Input
        onChange={setForm}
        label="Email Address"
        name="email"
        type="email"
        defaultValue={email}
        normalize={true}
        placeholder="email"
        error={data?.errors?.email}
      />
      <div className="col-span-full">
        <Input
          onChange={setForm}
          label="Address"
          name="address"
          normalize={true}
          placeholder="Address"
          defaultValue={organization?.address}
          error={data?.error?.address}
        />
      </div>
      <Input
        onChange={setForm}
        label="Phone Number"
        name="phone_number"
        defaultValue={phone_number}
        normalize={true}
        placeholder="Phone number"
        error={data?.errors?.phone_number}
      />

      <Countries
        setStates={setStates}
        onChange={setForm}
        label="Country"
        name="country"
        placeholder={organization?.country}
        error={data?.errors?.country}
      />
      <States
        states={states}
        onChange={setForm}
        label="State"
        name="state"
        placeholder="Search States"
        error={data?.errors?.state}
      />
      <Input
        onChange={setForm}
        label="City"
        name="city"
        normalize={true}
        placeholder="City"
        defaultValue={organization?.city}
        error={data?.error?.city}
      />
      <Input
        onChange={setForm}
        label="Account Number"
        name="account_number"
        type="number"
        normalize={true}
        maxLength={10}
        defaultValue={organization?.account_number}
        placeholder="Enter account number"
        // options={{ number: true }}
        error={data?.errors?.account_number}
      />
      <Banks
        top={true}
        onChange={setForm}
        label="Bank Name"
        placeholder={organization?.bank_name}
        name="bank_name"
        error={data?.errors?.bank_name}
      />
    </div>
  );
};

const KYCDocuments = ({
  utilityBill,
  directorValidId,
  cacForm,
  cac,
  setForm,
  data,
  isHidden,
}) => {
  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-2 w-full gap-5 md:gap-10 data-[hidden=true]:hidden"
      data-hidden={!isHidden}
    >
      <FileInput
        label="Director's Valid Id"
        name="director_id"
        def={directorValidId}
      />
      <FileInput
        label="Valid 3 Months Utility Bill"
        def={utilityBill}
        name="utility"
      />
      <FileInput label="CAC Certificate" name="cac" def={cac} />
      <Input
        onChange={setForm}
        label="BANK VERIFICATION NUMBER"
        name="director_bvn"
        error={data?.errors?.director_bvn}
        normalize={true}
        placeholder="Bank Verification Number"
        defaultValue={UserObject.getUser()?.organization?.director_bvn}
      />
      <FileInput
        label="Certified True Copy of the Form CAC/BN1"
        def={cacForm}
        name="cac_form"
      />
    </div>
  );
};

const ReSubmitKYC = ({ v, setV }) => {
  const [mandate, setMandate] = useState([]);
  const utilityBill = { file: mandate, setFile: setMandate };
  const [recentInvoice, setRecentInvoice] = useState([]);
  const cacForm = { file: recentInvoice, setFile: setRecentInvoice };
  const [bankStatement, setBankStatement] = useState([]);
  const directorValidId = { file: bankStatement, setFile: setBankStatement };
  const [cacC, setCacC] = useState([]);
  const cac = { file: cacC, setFile: setCacC };

  const { data, submit, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    const transData = new FormData();
    v.director_id_status === "2" &&
      transData.append("director_id", directorValidId?.file[0]);
    v.cac_form_status === "2" && transData.append("cac_form", cacForm?.file[0]);
    v.cac_status === "2" && transData.append("cac", cac.file[0]);
    v.utility_status === "2" &&
      transData.append("utility", utilityBill?.file[0]);

    submit(transData, {
      action: "dashboard",
      method: "put",
      encType: "multipart/form-data",
    });
  };

  useEffect(() => {
    if (data) {
      if (data?.success) {
        flashToast(data?.success, data?.message);
        UserObject.getUser().refresh();
        setV(false);
      }
      if (!data?.success) {
        flashToast(data?.success, data?.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    v && (
      <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full min-w-full min-h-screen bg-purple-500/50">
        <div className="relative w-full max-w-sm p-6 bg-white rounded-md shadow-lg">
          <button
            className="absolute bg-white rounded-full -top-5 -left-5"
            onClick={() => {
              setV(false);
            }}
          >
            <BsX className="w-6 h-6 text-gray-500" />
          </button>
          <h3 className="text-[#101828] font-[500] text-[18px] leading-[28px]">
            Re-Upload Rejected KYC Document
          </h3>
          <form
            className="flex flex-col items-start justify-start w-full mt-5 space-y-5"
            // method={formMethod("PUT")}
            // encType="multipart/form-data"
            onSubmit={handleSubmit}
          >
            {v.director_id_status === "2" && (
              <FileInput
                label="Director's Valid Id"
                placeholder="Upload Your Director's Id"
                name="director_id"
                def={directorValidId}
              />
            )}
            {v.utility_status === "2" && (
              <FileInput
                label="Valid 3 Months Utility Bill"
                placeholder="Upload Your Utility Bill"
                def={utilityBill}
                name="utility"
              />
            )}
            {v.cac_status === "2" && (
              <FileInput
                label="CAC Certificate"
                name="cac"
                def={cac}
                placeholder="Upload Your CAC Certificate"
              />
            )}
            {v.cac_form_status === "2" && (
              <FileInput
                label="Certified True Copy of the Form CAC/BN1"
                def={cacForm}
                name="cac_form"
                placeholder="Upload Your CAC/BN1 Form"
              />
            )}
            <Submit isLoading={state !== "idle"}>Submit</Submit>
          </form>
        </div>
      </div>
    )
  );
};

const FileInput = ({
  label,
  placeholder,
  note,
  def,
  multiple = false,
  ...props
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    def.setFile(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <div className="inline-flex flex-col items-start justify-start w-full">
      <span className=" capitalize text-gray-700 text-base w-full text-[18px] font-[700]">
        {label}
      </span>
      <label className="w-full px-4 py-4 mt-2 text-base font-semibold tracking-wide bg-transparent border form-input focus:outline-none text-gray-800s focus:border-purple-800 placeholder:capitalize">
        <input
          type="file"
          className="hidden"
          onChange={(e) => {
            setFiles((v) =>
              multiple ? [...v, ...e.target.files] : [...e.target.files]
            );
          }}
          {...props}
        />
        <div className="flex items-center justify-between">
          <p className="text-gray-400">{placeholder}</p>
          <MdOutlineDriveFolderUpload className="w-6 h-6" />
        </div>
      </label>
      <p className="mt-[7.8px] font-[700] text-[14px] leading-[21px] text-[#A0ABBB]">
        {note}
      </p>
      <div className="flex flex-col items-start justify-start w-full mt-2 space-y-2">
        {files.map((v, i) => (
          <div
            key={i}
            className="flex flex-col items-start justify-start w-full"
          >
            <div className="flex items-center justify-between w-full">
              <p>{v.name}</p>
              <button className="focus:outline-none">
                <BsX className="w-4 h-4" />
              </button>
            </div>
            <div className="w-full h-2 bg-green-600 rounded-full"></div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default DashboardIndex;
