import {BsEye, BsEyeSlash} from "react-icons/bs";
import Input from "./Input";
import {useState} from "react";

const Password = ({...props}) => {

    const [isReveal, setIsReveal] = useState(false)
    const handleClick = () => {
        setIsReveal(!isReveal)
    }

    const Button = () => (
        <button onClick={handleClick}>
            <BsEye className={`w-4 h-4 text-purple-700 ${isReveal ? '' : 'hidden'}`}/>
            <BsEyeSlash className={`w-4 h-4 text-purple-700 ${isReveal ? 'hidden' : ''}`}/>
        </button>
    );

    return (
        <>
            <Input
                type={isReveal ? 'text' : 'password'}
                {...props}
                icon={<Button />}
            />
        </>
    )
}

export default Password;