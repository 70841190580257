import { Suspense } from "react";
import { BsThreeDots } from "react-icons/bs";
import { Await } from "react-router-dom";
import usePagination from "../Dashboard/Table/usePagination";

const PaginateAll = ({ items }) => {
    const { Next, Prev, Button, Pagination, setPage, PageSize, Description } = usePagination();
    return (
        <div className="flex items-center justify-between mt-2">
            <Description data={items} />
            <PageSize numbers={[5, 10, 20, 50]} />
            <Pagination>
                <Suspense fallback={<p></p>}>
                    <Await
                        resolve={items?.data}
                        errorElement={
                            <span>
                                Loading
                            </span>
                        }
                    >
                        {(data) => {
                            if (!data) {
                                return null;
                            }

                            return (
                                <>
                                    <Prev onClick={() => {
                                        setPage(1)
                                    }} />
                                    {data?.data?.current_page !== 1 && <Button isActive={false}
                                        onClick={() => {
                                            setPage(1)
                                        }}>
                                        {1}
                                    </Button>}
                                    {data?.data?.current_page !== 1 && < BsThreeDots className="w-4 h-4" />}
                                    {data?.data?.current_page !== 1 && data?.data?.current_page !== 2 && <Button isActive={false}
                                        onClick={() => {
                                            setPage(data?.data?.current_page - 1)
                                        }}>
                                        {data?.data?.current_page - 1}
                                    </Button>}
                                    <Button isActive={true}
                                        onClick={() => {
                                            setPage(data?.data?.current_page)
                                        }}>
                                        {data?.data?.current_page}
                                    </Button>
                                    {data?.data?.current_page !== data?.data?.last_page && data?.data?.current_page !== data?.data?.last_page - 1 && <Button isActive={false}
                                        onClick={() => {
                                            setPage(data?.data?.current_page + 1)
                                        }}>
                                        {data?.data?.current_page + 1}
                                    </Button>}
                                    {data?.data?.current_page !== data?.data?.last_page && <BsThreeDots className="w-4 h-4" />}
                                    {data?.data?.current_page !== data?.data?.last_page && <Button isActive={false}
                                        onClick={() => {
                                            setPage(data?.data?.last_page)
                                        }}>
                                        {data?.data?.last_page}
                                    </Button>}
                                    <Next onClick={() => {
                                        setPage(data?.data?.last_page)
                                    }} />
                                </>
                            )
                        }}
                    </Await>
                </Suspense>
            </Pagination>
        </div>
    )
}


export default PaginateAll;