import Password from "../../Components/Forms/Password";
import Submit from "../../Components/Forms/Submit";
import {useFetcher, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import HeaderLogo from "../../Components/LandingPage/HeaderLogo";

const ResetPassword = () => {
    const {Form, formAction, data, state} = useFetcher()
    const navigate = useNavigate()

    const {search} = useLocation()
    const search1 = new URLSearchParams(search)

    useEffect(() => {
        if (data?.success) {
            navigate("/auth/password-reset")
        }
    })

    return (
        <div className="w-full h-full md:pt-48">
            <HeaderLogo/>

            <h1 className="mb-10 mt-20" style={{fontSize: '45px', fontWeight: 800, lineHeight: '55px'}}>Reset
                Password</h1>
            <Form action={formAction} method="post">
                <Password
                    label="New password"
                    name="password"
                    placeholder="Enter new Password"
                    error={data?.errors?.password}
                />
                <Password
                    label="Confirm Password"
                    name="password_confirmation"
                    placeholder="Confirm Password"
                    error={data?.errors?.password_confirmation}
                />
                <input type="hidden" name="token" value={search1.get('token') ?? ''}/>
                <Submit
                    isLoading={state !== 'idle'}
                >Reset Password</Submit>
            </Form>
        </div>
    )
}

export default ResetPassword;