import {Outlet} from "react-router-dom";
import Nav from "../Components/LandingPage/NavBar/Nav";
import Footer from "../Components/LandingPage/Footer";
import JoinCommunity from "../Components/LandingPage/JoinCommunity";
import '../Components/LandingPage/style.css';
// import ScrollToTop from "../Components/LandingPage/NavBar/ScrollToTop";

const LandingPage = () => {
    return (
        <div className="font-montserrat h-full overflow-y-auto overflow-x-hidden w-full">
            <div className="fixed top-0 left-0 w-full bg-white">
                {/* <ScrollToTop /> */}
                <Nav/>
            </div>
            <div className="flex-grow mt-[100px]">
                <Outlet/>
            </div>
            <div className="md:px-[200px] px-[20px]">
                <JoinCommunity/>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}

export default LandingPage;