import { Spinner } from "flowbite-react";

const Submit = ({ children, isLoading, ...props }) => {
  return (
    <button
      className="py-3 w-full px-8 rounded-md tracking-wider text-base min-h-[59px] font-bold bg-[#590DA4] text-white disabled:opacity-50 hover:bg-purple-900"
      disabled={isLoading}
      {...props}
    >
      {!isLoading && children}
      {isLoading && (
        <div className="flex items-center justify-center">
          <Spinner color="info" aria-label="It is loading" />
          <span className="ml-2">Please wait...</span>
        </div>
      )}
    </button>
  );
};

export default Submit;
