import DataObject from "./DataObject";
import { getFromStore, removeFromStore, store } from "../Helpers/Storage";
import { redirect } from "react-router-dom";
import ProfileIcon from "./../Assets/images/ProfileIcon.png";
import ResponseObject from "./ResponseObject";

class UserObject extends DataObject {
  data;

  constructor(data) {
    super(data);
    this.data = data ?? {};
    this.active_status = data?.active_status;
    this.company_name = data?.company_name;
    this.created_at = data?.created_at;
    this.email = data?.email;
    this.email_verified_at = data?.email_verified_at;
    this.first_name = data?.first_name;
    this.gender = data?.gender;
    this.id = data?.id;
    this.last_name = data?.last_name;
    this.list_join_date = data?.list_join_date;
    this.on_wait_list = data?.on_wait_list;
    this.permissions = data?.permissions;
    this.phone_number = data?.phone_number;
    this.pin = data?.pin;
    this.profile_id = data?.profile_id;
    this.role_token = data?.role_token;
    this.social_id = data?.social_id;
    this.social_type = data?.social_type;
    this.status = data?.status;
    this.token = data?.token;
    this.updated_at = data?.updated_at;
    this.username = data?.username;
    this.verification_status = data?.verification_status;
    this.role = data?.role;
    this.twitter_handle = data?.twitter_handle;
    this.facebook_handle = data?.facebook_handle;
    this.instagram_handle = data?.instagram_handle;
    this.profile_photo = data?.profile_photo_path ?? ProfileIcon;
    this.organization = data?.organization;
    this.exist = data?.exist;
  }

  isKYCVerified() {
    return parseInt(this.organization?.kyc_verification_status) === 1;
  }

  isAdmin() {
    return this.role === "admin";
  }

  isOnWaitingList() {
    return this.on_wait_list === "1";
  }

  isVerified() {
    return this.verification_status === "1";
  }

  isAuthenticated() {
    return this.token;
  }

  fullname() {
    return this.first_name + " " + this.last_name;
  }

  refresh() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + UserObject.getUser().token);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch("https://api.fedhagap.com/api/user/token/refresh", requestOptions)
        .then((r) => r.json())
        .then((r) => {
          const response = ResponseObject.from({ data: r });
          UserObject.from(response.data).storeUser();
        });
    } catch (e) {
      return e?.response;
    }
  }

  storeUser() {
    store("user", this.data);
  }

  static getUser() {
    return UserObject.from(getFromStore("user"));
  }

  static logOutUser() {
    removeFromStore("user", null);
    redirect("/auth/login");
  }
}

export default UserObject;
