import {Outlet, useNavigate} from "react-router-dom";
import useToggler from "../Hooks/useToggler";
import NavBar from "../Components/Dashboard/NavBar";
import TopBar from "../Components/Dashboard/TopBar";
import ProtectedRoute from "../Routes/ProtectedRoute";
import {useEffect} from "react";
import useIdle from "../Hooks/useIdle";
import UserObject from "../DataObjects/UserObject";
import Toast from "../Components/Dashboard/Notification/Toast";

const Dashboard = () => {
    const [value, click] = useToggler(false);
    const isIdle = useIdle({timeToIdle: 1.8e+6, inactivityEvents: []});
    const navigate = useNavigate();

    useEffect(() => {
            if (isIdle) {
                UserObject.logOutUser();
                navigate("/auth/login")
            }
        },
        [isIdle, navigate])

    return (
        <ProtectedRoute>
            <Toast/>
            <div className="font-montserrat relative flex items-start justify-start w-full h-screen bg-[#F7F8F9]">
                <NavBar value={value} click={click}/>
                <div className="flex-1 flex flex-col items-start justify-start w-full h-screen">
                    <TopBar value={value} click={click}/>
                    <div className="py-10 md:px-16 px-8 overflow-y-auto w-full h-full">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    );
};

export default Dashboard;
