import React, {useEffect} from "react";
import AboutHeader from "../../Assets/images/AboutHeader.png";
import Star from "../../Assets/icons/Star.svg";
import Heart from "../../Assets/icons/Heart.svg";
import Tick from "../../Assets/icons/Tick.svg";
import Innovation from "../../Assets/icons/Innovation.svg";
import Chat from "../../Assets/icons/Chat.svg";
import JobCard from "./JobCard";

import Hiring from "../../Assets/images/Hiring.png";

const AboutUs = () => {
    useEffect(() => {
        document.title = "About Us | Fedhagap SMEs";
    }, []);

    return (
        <div className=" h-full overflow-y-auto overflow-x-hidden md:px-20 w-full content-wrapper">
            <div className="flex-grow">
                {/*<Outlet />*/}
                <div style={{marginTop: "10px"}}>
                    <header
                        className="p-14 md:mx-32 mt-10 text-center"
                        style={{
                            backgroundImage: `url(${AboutHeader})`,
                            backgroundPosition: "left top",
                            backgroundSize: "85%",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <p className="page-title-sub text-[#590DA4]">About Us</p>
                        <p className="page-title text-[#0D0F11]">
                            We are a Team committed to empowering <br/> businesses across
                            Africa
                        </p>
                    </header>
                </div>

                <section
                    className="md:p-10 p-5 mb-20 mt-6"
                    style={{background: "#FAF7FC", borderRadius: "32px"}}
                >
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-6">
                        <div className="col-span-12 md:col-span-4">
                            <div className="our-purpose p-8">
                                <p className="mb-4">Our Purpose</p>
                                <span>
                  FedhaGap exists to ensure the survival of NMSMEs in Africa, by
                  removing the cash constraints for them through the utilization
                  of technology tools to provide seamless invoice and inventory
                  finance solutions. We are committed to reducing the failure
                  rate of NMSMES, one business at a time, in support of
                  Sustainable Development Goal (SDG) 8.
                </span>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-8">
                            <div className="grid px-5 grid-cols-12 gap-5">
                                <div className="about-core-values col-span-6 md:col-span-4">
                                    <img src={Star} alt=""/>
                                    <p>Excellence</p>
                                    <span>
                    We strive for excellence in all we do. We are dedicated to
                    providing best-in-class experience for our stakeholders
                    across all touchpoints.
                  </span>
                                </div>

                                <div className="about-core-values col-span-6 md:col-span-4">
                                    <img src={Innovation} alt=""/>
                                    <p>Innovation</p>
                                    <span>
                    We are committed to consistent product and process
                    innovation. We are inspired by new ways of solving problems
                    for our community.
                  </span>
                                </div>

                                <div className="about-core-values col-span-6 md:col-span-4">
                                    <img src={Tick} alt=""/>
                                    <p>Accountability</p>
                                    <span>
                    Our actions are governed by ethics, professionalism and
                    transparency. We are accountable to our investors,
                    customers, team and our community.
                  </span>
                                </div>

                                <div className="about-core-values col-span-6 md:col-span-4">
                                    <img src={Heart} alt=""/>
                                    <p>Relationship</p>
                                    <span>
                    We prioritize and invest in our relationships with the NMSME
                    community. Through our capacity building programs, grants,
                    consulting services and advocacy, we have established our
                    position as leaders in the West African NMSME ecosystem.
                  </span>
                                </div>

                                <div className="about-core-values col-span-6 md:col-span-4">
                                    <img src={Chat} alt=""/>
                                    <p>Collaboration</p>
                                    <span>
                    We believe that our strength lies in our ability to combine
                    our skills and resources to achieve the common good. We
                    believe that the strength in the collective outweighs that
                    of the individual.
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="my-10 mb-20 px-5 md:px-10">
          <div
            className="text-center py-30"
            style={{ fontWeight: "900", fontSize: "32px", lineHeight: "37px" }}
          >
            Our Team Members
          </div>

          <div className="grid mt-10 grid-cols-12 gap-5">
            <div className="col-span-6 md:col-span-3">
              <StaffCard
                name="John Doe"
                title="Chief Executive Officer"
                picture={Staff1}
              />
            </div>

            <div className="col-span-6 md:col-span-3">
              <StaffCard name="Janet Doe" title="Model" picture={Staff2} />
            </div>

            <div className="col-span-6 md:col-span-3">
              <StaffCard
                name="Deji Busari"
                title="Software Engineer"
                picture={Staff3}
              />
            </div>

            <div className="col-span-6 md:col-span-3">
              <StaffCard name="Deji Busari" title="CEO" picture={Staff1} />
            </div>

            <div className="col-span-6 md:col-span-3">
              <StaffCard name="Janet Doe" title="Model" picture={Staff2} />
            </div>

            <div className="col-span-6 md:col-span-3">
              <StaffCard
                name="Deji Busari"
                title="Software Engineer"
                picture={Staff3}
              />
            </div>

            <div className="col-span-6 md:col-span-3">
              <StaffCard
                name="John Doe"
                title="Chief Executive Officer"
                picture={Staff1}
              />
            </div>

            <div className="col-span-6 md:col-span-3">
              <StaffCard name="Janet Doe" title="Model" picture={Staff2} />
            </div>
          </div>
        </section> */}

                <section className="py-20 mb-10 px-8 md:px-10">
                    <div>
                        <span className="text-[#590DA4]"
                              style={{fontWeight: '700', fontSize: '18px'}}>We are Hiring</span>
                        <p style={{fontWeight: 700, fontSize: '32px'}}>Join the Team</p>
                    </div>

                    <div className="grid mt-10 grid-cols-12 gap-5 md:gap-10">

                        <div className="col-span-12 md:col-span-6">
                            <JobCard dept="Marketing" title="Community Manager" type="Full Time" location="Remote"/>
                            <JobCard dept="Technical" title="Software Engineer Intern" type="Full Time"
                                     location="Remote"/>
                            {/* <JobCard dept="Support" title="Community Manager" type="Full Time" location="Remote" />
              <JobCard dept="Support" title="Customer Services" type="Part Time" location="Remote" /> */}
                        </div>

                        <div className="col-span-12 hidden md:block md:col-span-6">
                            <img src={Hiring} alt=""/>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AboutUs;
