import { Suspense } from "react";
import { Await, Link, useLoaderData } from "react-router-dom";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import moment from "moment";
import Panel from "../../../Components/Dashboard/Panel";
import useTitle from "../../../Hooks/useTitle";
import useTable from "../../../Components/Dashboard/Table/useTable";
import PaginateAll from "../../../Components/Utils/PaginateAll";
import Search from "antd/es/transfer/search";
import Portal from "../../../Components/Dashboard/Portal";
import { BsDownload } from "react-icons/bs";
import DateRange from "../../../Components/Dashboard/DateRange";

const AuditLogs = () => {
    useTitle('Audit Logs')
    const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
    const items = useLoaderData()

    return (
        <>
            <Panel>
                <Table>
                    <RowHeader>
                        <ColumnHeader>
                            Email Address
                        </ColumnHeader>
                        <ColumnHeader>
                            Type
                        </ColumnHeader>
                        <ColumnHeader>
                            Action
                        </ColumnHeader>
                        <ColumnHeader>
                            Timestamp
                        </ColumnHeader>
                    </RowHeader>
                    <Body data={items}>
                        {data => (data?.data?.data?.map((v, i) => (<Row key={i}>
                            <Column>
                                {v?.user?.email}
                            </Column>
                            <Column>
                                {v.event_type}
                            </Column>
                            <Column>
                                {v.activity}
                            </Column>
                            <Column>
                                {moment(v.created_at).format('llll')}
                            </Column>
                        </Row>)))}
                    </Body>
                </Table>
                <AuditLogEmptyState items={items} />
            </Panel>
            <PaginateAll items={items} />
        </>
    )
}

const AuditLogEmptyState = ({ items }) => {
    return (
        <Suspense fallback={<div></div>}>
            <Await
                resolve={items?.data}
            >
                {(data) => {
                    if (data?.data?.data.length <= 0) {
                        return (
                            <div
                                className="bg-white rounded-[16px] min-h-[400px] md:min-h-[600px] text-center flex justify-center flex-col items-center">
                                <img src={InvoiceEmpty} alt="Transactions" />
                                <p className="mt-5 text-[#64748B]">You don’t have any transaction <br /> at the time</p>
                                <Link to="/dashboard/transaction/create" className="mt-5">
                                    <span className="bg-black text-white px-6 py-3  rounded-[8px]">
                                        + New Request
                                    </span>
                                </Link>
                            </div>
                        )
                    }

                    return (
                        <Portal selector="#portal">
                            <div className="inline-flex items-center justify-end space-x-2 mt-2">
                                <Search />
                                <DateRange />
                                <button className="">
                                    <span className="bg-black whitespace-nowrap text-white p-2  rounded-[8px] inline-block">
                                        <BsDownload className="w-4 h-4 text-white" />
                                    </span>
                                </button>
                            </div>
                        </Portal>
                    )
                }}
            </Await>
        </Suspense>
    )
}

export default AuditLogs;