import {useEffect, useState} from "react";

const useCountUp = (max = 100, interval = 1000, increment = 10) => {

    const [value, setValue] = useState(0)

    useEffect(() => {
        const timer = value >= 0 && setInterval(() => {
            // let i = increment >= max ? max : increment;
            // if(increment>max){
            //     setValue(max)
            //     return
            // }
            setValue(v => v + 1)
        }, interval)

        if (value >= max) {
            clearInterval(timer);
        }

        return () => {
            clearInterval(timer);
        }
    }, [value])

    return value
}

export default useCountUp;