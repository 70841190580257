import http from "../Store/baseHttp";
import ResponseObject from "../DataObjects/ResponseObject";
import UserObject from "../DataObjects/UserObject";
import { redirect } from "react-router-dom";

export const RegisterService = async (v) => {
    const { request } = v;
    try {
        const data = Object.fromEntries(await request.formData())
        let response = await http.post('/register', data)
        return response?.data;
    } catch (e) {
        return e?.response?.data
    }
}

export const AuthLoaderService = async (v) => {
    // const {request} = v;
    const user = UserObject.getUser()
    if (user.isAuthenticated()) {
        return redirect("/dashboard");
    }
}

export const VerifyService = async (v) => {
    const { request } = v
    try {
        const data = Object.fromEntries(await request.formData())
        let response = await http.post('/verification/verify', data)
        return response.data;
    } catch (e) {
        return e?.response.data
    }
}

export const ResendVerificationService = async (v) => {
    const { request } = v;
    try {
        const data = Object.fromEntries(await request.formData())
        let response = await http.post('/verification/resend', data)
        return response.data;
    } catch (e) {
        return e?.response.data
    }
}

export const VerifyPasswordService = async (v) => {
    const { request } = v;
    try {
        const data = Object.fromEntries(await request.formData())
        let response = await http.post('/password/verify-code', data)
        return response.data;
    } catch (e) {
        return e?.response.data
    }
}

export const ResendPasswordVerificationService = async (v) => {
    const { request } = v;
    try {
        const data = Object.fromEntries(await request.formData())
        let response = await http.post('/verification/resend', data)
        return response.data;
    } catch (e) {
        return e?.response.data
    }
}

export const LoginService = async (v) => {
    const { request } = v;
    try {
        let response = await http.post('/login', Object.fromEntries(await request.formData()))
        const res = ResponseObject.from(await response);

        if (res.success) {
            let user = UserObject.from(await res.data)
            if (!user.isVerified()) {
                const email = user.email
                return redirect("/auth/verify?email=" + email)
            }

            user.storeUser()
            if (user.isOnWaitingList()) {
                return redirect("/dashboard");
            }

            if (!user.isOnWaitingList()) {
                return redirect("/dashboard/apply?email=" + user?.email)
            }
        }
        return res;
    } catch (e) {
        return ResponseObject.from(e?.response)
    }
}

export const ForgotPasswordService = async (v) => {
    const { request } = v;
    try {
        let response = await http.put('/password/forgot', Object.fromEntries(await request.formData()))
        response = ResponseObject.from(response)
        return response;
    } catch (e) {
        return ResponseObject.from(e?.response)
    }
}

export const VerifyResetService = async (v) => {
    const { request } = v;
    try {
        let response = await http.post('/password/verify-code', Object.fromEntries(await request.formData()))
        return response.data;
    } catch (e) {
        return e?.response?.data
    }
}

export const ResetPasswordService = async (v) => {
    const { request } = v;
    try {
        let response = await http.post('/password/reset', Object.fromEntries(await request.formData()))
        return response?.data;
    } catch (e) {
        return e?.response?.data
    }
}


export const SocialAuthenticationService = async (data) => {
    try {
        let response = await http.post('/password/reset', data)
        return response?.data;
    } catch (e) {
        return e?.response?.data
    }
}