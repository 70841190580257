import LogoFooter from "./../../Assets/logo/LogoFooter.png";
import { Footer as FT } from "flowbite-react";
import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
      <footer className="w-full lg:px-10 py-10" style={{borderTop: "2px solid #E7EAEE", fontSize: "18px!important"}}>
        <div className="w-full grid md:grid-cols-12 py-10 footer-text">
          {/* <div className="grid w-full sm:flex sm:justify-between md:flex justify-between md:grid-cols-1"> */}
          <div className="md:col-span-3 mb-10 col-span-12 p-4">
            <Link to="/">
              <img src={LogoFooter} alt="logo" style={{height: "72px"}} />
            </Link>
          </div>
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 sm:mt-4 md:grid-cols-4 sm:gap-6 w-full"> */}
          <div className="md:col-span-2 col-span-6 pl-5 md:pl-0 mb-10 md:mb-2 font-[18px]">
            <FT.LinkGroup col={true}>
              <HashLink smooth to="/#products"><span className="footer-text">Products</span></HashLink>
              <HashLink smooth to="/#how-it-works"><span>How it works</span></HashLink>
              <HashLink smooth to="/#target-market"><span>Our target market</span></HashLink>
            </FT.LinkGroup>
          </div>
          <div className="md:col-span-2 col-span-6 pl-5 md:pl-0 mb-10 md:mb-2">
            <FT.LinkGroup col={true}>
              <Link to="/terms-and-conditions"><span>Terms & Services</span></Link>
              <Link to="/privacy-policy"><span>Privacy Policy</span></Link>
            </FT.LinkGroup>
          </div>
          <div className="md:col-span-2 col-span-12 pl-5 md:pl-0 mb-10 md:mb-2 mr-5">
            <FT.LinkGroup col={true}>
              <Link to="/contact-us"><span>Contact Us</span></Link>
              <a href="mailto:contact@fedhagap.com"><span style={{fontSize: '12px!important'}}>Say hi to us at <small className="text-[#590DA4]">contact@fedhagap.com</small></span></a>
            </FT.LinkGroup>
          </div>
          <div className="md:col-span-3  col-span-12 pl-5 md:pl-0 mb-10 md:mb-2">
            <FT.LinkGroup col={true}>
              <Link className="cursor-text font-bold" href="#">
                <span className="f-[22px]" style={{fontSize: '15px'}}>Follow Us On Social Media</span>
              </Link>
              <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-start">
                {/* <FT.Icon href="#" icon={BsFacebook} /> */}
                <FT.Icon href="https://instagram.com/fedhagap?igshid=YmMyMTA2M2Y=" icon={BsInstagram} />
                <FT.Icon href="https://twitter.com/fedhagap?s=21&t=Rb4oLOpLEYZHa71nW6155Q" icon={BsTwitter} />
                <FT.Icon href="https://www.linkedin.com/company/fedhagap/" icon={BsLinkedin} />
              </div>
            </FT.LinkGroup>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
        {/* <FT.Divider /> */}
          <div className="w-full flex items-center justify-center mb-5">
            <FT.Copyright
              href="#"
              by="- All Rights Reserved - FedhaGap"
              year={2022}
              className="text-center copyright"
            />
          </div>
      </footer>
  );
};

export default Footer;
